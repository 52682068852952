import { make } from "vuex-pathify"
import camelize from "camelize2"
import { api } from "@/api"
import i18n from "@/languages"
import { getSpecializationTranslation } from "@/utils/functions"

const listMapper = (list) => {
  return list.map((item) => ({
    value: item.id,
    text: item.name,
  }))
}

const specializationsListMapper = (list) => {
  return list.map((item) => ({
    value: item.id,
    text: getSpecializationTranslation(item, i18n.locale),
  }))
}

const langugagesListMapper = (list) => {
  return list.map((item) => ({
    value: item.id,
    text: item.name,
    locales: {
      en: {
        nominative: item.name,
      },
      pl: {
        nominative: item.name_pl || item.name,
        genitive: item.conjugated_name || item.name_pl || item.name,
      },
    },
  }))
}

// extract images from folder
export let dummyImages = require.context("../assets/dummyImages/", false, /\.png$/)
dummyImages = dummyImages.keys().map(dummyImages)

const state = () => ({
  teacherData: {},
  isLoading: false,
  languages: [],
  specializations: [],
  levels: [],
  reminderMessage: {},

  /* --------------------------------- filters -------------------------------- */
  teachers: [],
  itTeachers: [],
  teachersPagination: {
    currentPage: null,
    lastPage: null,
    perPage: null,
    total: null,
  },

  language: null,
  level: null,
  day: null,
  time: { to: null, from: null },
})

// make all mutations
const mutations = make.mutations(state)

export default {
  namespaced: true,
  state,
  getters: {
    getTeacherInfo: (state) => state.teacherData,
    getLoadingStatus: (state) => state.isLoading,
    getReminderMessage: (state) => state.reminderMessage,
  },
  mutations: {
    ...mutations,
    APPEND_TEACHERS: (state, payload) => {
      state.teachers.push(...payload)
    },
    PREPEND_TEACHERS: (state, payload) => {
      state.teachers.unshift(...payload)
    },
    RESET_TEACHERS: (state) => {
      state.teachers = []
      state.teachersPagination = {
        currentPage: null,
        lastPage: null,
        perPage: null,
        total: null,
      }
    },
    RESET_FILTERS: (state) => {
      state.language = null
      state.level = null
      state.day = null
      state.time = { to: null, from: null }
    },
  },
  actions: {
    resetFilters: ({ commit }) => {
      commit("RESET_FILTERS")
    },
    resetTeachers: ({ commit }) => {
      commit("RESET_TEACHERS")
    },
    getLanguages: async ({ commit }) => {
      const res = await api.getLanguages()
      commit("SET_LANGUAGES", res.data)
    },
    getLevels: async ({ commit }) => {
      const res = await api.getLevels()
      commit("SET_LEVELS", res.data)
    },
    async searchTeachers({ commit }, payload) {
      const { mode, formData, reset = false } = payload
      const { data: response } = await api.searchTeachers(formData)
      // TODO add camesize for data
      const { data, ...pagination } = response
      commit("SET_TEACHERS_PAGINATION", camelize(pagination))

      // if previous mode was unset then should reset the list before upending/prepending
      if (reset) {
        commit("SET_TEACHERS", [])
      }

      switch (mode) {
        case "append":
          commit("APPEND_TEACHERS", data)
          break
        case "prepend":
          commit("PREPEND_TEACHERS", data)
          break
        default:
          commit("SET_TEACHERS", data)
          break
      }
      return Promise.resolve()
    },
    async getDummyTeachers({ commit }) {
      let { data } = await api.getDummyTeachers()

      data = data.map((item, index) => ({ ...item, image_url: dummyImages.at(index) }))

      commit("SET_TEACHERS", data)

      return Promise.resolve()
    },
    setTeacherInfo: async ({ commit }, teacher_id) => {
      commit("SET_IS_LOADING", true)
      const res = await api.getTeacherInfo(teacher_id)
      commit("SET_TEACHER_DATA", res)
      commit("SET_IS_LOADING", false)
    },
    setLanguages: async ({ commit }) => {
      const { data } = await api.getLanguages()
      if (data) {
        commit("SET_LANGUAGES", langugagesListMapper(data))
      }
    },
    setSpecializations: async ({ commit }) => {
      const { data } = await api.getSpecializations()
      if (data) {
        commit("SET_SPECIALIZATIONS", specializationsListMapper(data))
      }
    },
    setLevels: async ({ commit }) => {
      const { data } = await api.getLevels()
      if (data) {
        commit("SET_LEVELS", listMapper(data))
      }
    },
    getItTeachers: async ({ commit }) => {
      try {
        commit("SET_IS_LOADING", true)
        const { data } = await api.getItTeachers()
        commit("SET_IT_TEACHERS", data)
      } catch (error) {
        console.error(error)
      } finally {
        commit("SET_IS_LOADING", false)
      }
    },
  },
}
