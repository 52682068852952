import { mapState } from "vuex"

export default {
  methods: {
    translateLanguageById(lang) {
      const language = this.languages.find((language) => language?.value === lang?.id)
      const translation = language
        ? language?.locales[this.locale].nominative
        : lang?.name || lang || ""
      return translation
    },
    translateLanguageByName(lang) {
      const language = this.languages.find(
        (item) =>
          item?.text?.toLowerCase() === lang?.name?.toLowerCase() ||
          item?.text?.toLowerCase() === lang?.toLowerCase(),
      )
      const translation = language
        ? language?.locales[this.locale].nominative
        : lang || lang?.name || language?.text || ""
      return translation
    },
    translateLanuageByNameInGenitiveCase(lang) {
      const language = this.languages.find(
        (item) => item?.text?.toLowerCase() === lang?.toLowerCase(),
      )
      const nominative = language?.locales[this.locale]?.nominative || ""
      const genitive = language?.locales[this.locale]?.genitive || null
      return genitive ? genitive : nominative
    },
    getLanguagesString(languagesArray) {
      return (
        languagesArray
          ?.map((teacherLanguage) => {
            const languageName = teacherLanguage?.name || teacherLanguage
            return this.translateLanguageByName(languageName)
          })
          .join(", ") ?? []
      )
    },
  },
  computed: {
    ...mapState("teacher", ["languages"]),

    locale() {
      return this.$i18n.locale
    },
  },
}
