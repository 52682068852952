<template>
  <v-app>
    <Header v-if="showHeader" />
    <Dialogs></Dialogs>

    <v-main
      style="padding: 80px 0px 0px"
      :class="{ blured: activeDialog || activeMenu, entryPage }"
    >
      <FadeTransition mode="out-in">
        <router-view />
      </FadeTransition>
    </v-main>
  </v-app>
</template>

<script>
import { sync } from "vuex-pathify"
import { FadeTransition } from "vue2-transitions"
import { mapActions, mapGetters, mapMutations } from "vuex"
import Dialogs from "@/components/Dialogs"
import Header from "@/components/Header.vue"

import { navigatorLanguage } from "@/utils/navigatorLanguage"

export default {
  name: "App",
  components: {
    FadeTransition,
    Header,
    Dialogs,
  },
  computed: {
    ...mapGetters({
      dialogConfig: "layout/getDialogConfig",
      showHeader: "layout/getShowHeader",
    }),
    profile: sync("profile/profile"),
    auth: sync("user/auth"),
    role: sync("user/role"),
    activeMenu: sync("layout/activeMenu"),
    activeDialog() {
      return this.dialogConfig?.active
    },

    entryPage() {
      const entryPaths = ["login", "registration"]
      return entryPaths.some((val) => {
        return this.$route.path.includes(val)
      })
    },
  },
  watch: {
    profile: {
      handler(val) {
        // if user is logged as student in check if registration is completed
        if (this.role === "teacher") return
        const { registration_completed } = val
        localStorage.setItem("registration_completed", registration_completed)
      },
      deep: true,
    },
  },
  created() {
    const role = localStorage.getItem("role")?.toLowerCase()
    const auth = JSON.parse(localStorage.getItem("auth"))
    if (auth) {
      this.setAuth(auth)
    }
    if (role) {
      this.setRole(role)
    }

    localStorage.removeItem("tour")
  },
  mounted() {
    const availableLanguages = Object.keys(this.$i18n.messages)
    const defaultLocale = availableLanguages.includes(navigatorLanguage) ? navigatorLanguage : "en"
    if (this.$i18n) {
      this.$i18n.locale = defaultLocale
    }
    if (this.$vuetify?.lang) {
      this.$vuetify.lang.current = defaultLocale
    }
    this.setLanguages()
  },
  methods: {
    ...mapMutations({
      setError: "SET_ERROR_FROM_SERVER",
      setLocale: "SET_LOCALE",
    }),
    ...mapActions({
      setAuth: "user/setAuth",
      setRole: "user/setRole",
      setLanguages: "teacher/setLanguages",
    }),
  },
}
</script>

<style lang="scss">
@import "./sass/global.scss";

.entryPage {
  padding: 0 0 2rem 0 !important;
}
</style>
