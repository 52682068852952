<template>
  <SharedEditDialog>
    <template #title>
      <p class="title">{{ messages.editMyDetails }}</p>
    </template>
    <template #content>
      <Loading v-if="loading" class="loader"></Loading>
      <div v-else>
        <div class="d-flex flex-row flex-wrap pt-6">
          <div class="d-flex flex-column input-container mb-6 w-100">
            <label for="name" class="mb-1 input-container__label">{{ messages.name }}</label>
            <input
              id="name"
              v-model="credentials.name.value"
              type="text"
              :placeholder="messages.enterYourName"
              name="name"
              class="input-container__input"
              :class="{ 'input-container__input--error': credentials.name.errMsg }"
              @input="validateInputDefer($event, 'name')"
              @blur="validateInputDefer($event, 'name', 0)"
            />
            <p v-if="credentials.name.errMsg" class="input-container__error-message">
              {{ credentials.name.errMsg }}
            </p>
          </div>
          <div class="d-flex flex-column input-container mb-6 w-100">
            <label for="lastname" class="mb-1 input-container__label">{{
              messages.lastName
            }}</label>
            <input
              id="lastname"
              v-model="credentials.lastname.value"
              type="text"
              :placeholder="messages.enterYourLastName"
              name="lastname"
              class="input-container__input"
              :class="{ 'input-container__input--error': credentials.lastname.errMsg }"
              @input="validateInputDefer($event, 'lastname')"
              @blur="validateInputDefer($event, 'lastname', 0)"
            />
            <p v-if="credentials.lastname.errMsg" class="input-container__error-message">
              {{ credentials.lastname.errMsg }}
            </p>
          </div>
          <div class="d-flex flex-column input-container mb-6 w-100">
            <label for="phone" class="mb-1 input-container__label">{{
              messages.phoneNumber
            }}</label>
            <input
              id="phone"
              v-model.trim="credentials.phone.value"
              type="tel"
              placeholder="+48 123 456 789"
              name="phone"
              class="input-container__input"
              :class="{ 'input-container__input--error': credentials.phone.errMsg }"
              @input="validateInputDefer($event, 'phone')"
              @blur="validateInputDefer($event, 'phone', 0)"
            />
            <p v-if="credentials.phone.errMsg" class="input-container__error-message">
              {{ credentials.phone.errMsg }}
            </p>
          </div>
          <div class="w-100">
            <v-menu
              ref="dialog"
              v-model="modal"
              :close-on-content-click="false"
              :return-value.sync="credentials.date.value"
              persistent
              max-width="290px"
              transition="scroll-y-transition"
              offset-y
              left
              nudge-left="-30"
              nudge-bottom="15"
              origin="top"
              min-width="auto"
              class="input-container"
            >
              <template #activator="{ on, attrs }">
                <p class="mb-1 input-container__label">{{ messages.dateOfBirth }}</p>
                <v-text-field
                  :value="formatInputDate"
                  hide-details
                  name="date"
                  :placeholder="$t('profile.datePlaceholder')"
                  append-icon="$Calendar"
                  readonly
                  dense
                  v-bind="attrs"
                  class="input-container__date-picker w-100"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="credentials.date.value"
                header-color="#3c486f"
                color="#3c486f"
                flat
                :title-date-format="formatPickerTitleDate"
                @change="$refs.dialog.save(credentials.date.value)"
              >
              </v-date-picker>
            </v-menu>
          </div>
        </div>
      </div>
    </template>
    <template #actions="{ closeDialog }">
      <BaseButton
        outlined
        :clear="true"
        color="grayscale-darken3"
        class="mr-4 cancel-button"
        @click="closeDialog"
        >{{ messages.void }}</BaseButton
      >

      <BaseButton
        :disabled="!isFormValid"
        class="confirm-button"
        @click="closeDialog(submitChangeProfileDetails)"
        >{{ messages.confirmChanges }}</BaseButton
      >
    </template>
  </SharedEditDialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import validateInputDefer from "@/mixins/validateInputDefer"
import spinnerMixin from "@/mixins/spinnerMixin"
import notyMixin from "@/mixins/notyMixin"
import parseDate from "@/mixins/parseDate"
import { converDateToSlashFormat } from "@/utils/functions"

export default {
  mixins: [validateInputDefer, spinnerMixin, notyMixin, parseDate],

  data: () => ({
    modal: false,
    credentials: {
      name: {
        value: "",
        errMsg: "",
        timeout: null,
      },
      lastname: {
        value: "",
        errMsg: "",
        timeout: null,
      },
      phone: {
        value: "",
        errMsg: "",
        timeout: null,
      },
      date: {
        value: "",
        errMsg: "",
        timeout: null,
      },
    },
  }),
  computed: {
    messages() {
      return {
        name: this.$t("message.name"),
        lastName: this.$t("message.lastName"),
        phoneNumber: this.$t("message.phoneNumber"),
        dateOfBirth: this.$t("message.dateOfBirth"),
        editMyDetails: this.$t("message.editMyDetails"),
        cancel: this.$t("message.cancel"),
        confirm: this.$t("message.confirm"),
        void: this.$t("message.void"),
        confirmChanges: this.$t("message.confirmChanges"),
        approve: this.$t("message.approve"),
        close: this.$t("message.close"),
        enterYourName: this.$t("message.enterYourName"),
        enterYourLastName: this.$t("message.enterYourLastName"),
        error: {
          phone: this.$t("message.enterYourPhoneNumber"),
          date: this.$t("message.enterDate"),
          name: this.$t("message.enterYourName"),
          lastname: this.$t("message.enterYourLastName"),
        },
      }
    },
    isFormValid() {
      let allValues = true
      let anyErrors = false

      for (const element of Object.keys(this.credentials)) {
        if (!this.credentials[element].value) {
          allValues = false
        }
        if (this.credentials[element].errMsg) {
          anyErrors = true
        }
      }
      return allValues && !anyErrors
    },

    formatInputDate() {
      return converDateToSlashFormat(this.credentials.date.value)
    },

    ...mapGetters("layout", {
      dialogData: "getDialogConfig",
    }),
  },
  created() {
    this.credentials.name.value = this.dialogData.data.first_name
    this.credentials.lastname.value = this.dialogData.data.last_name
    this.credentials.phone.value = this.dialogData.data.phone_number
    const date = this.dialogData.data.date_of_birth
      ? new Date(this.dialogData.data.date_of_birth)
      : new Date()
    this.credentials.date.value = date.toISOString().substr(0, 10) || new Date()
  },
  methods: {
    ...mapActions("profile", {
      updateProfile: "updateProfile",
    }),

    validateInput(value, type) {
      const regex = {
        name: /^(?!\s*$).+/,
        lastname: /^(?!\s*$).+/,
        phone: /^(?!\s*$).+/,
      }
      this.$set(
        this.credentials[type],
        "errMsg",
        !!value && regex[type].test(value) ? "" : this.messages.error?.[type],
      )
    },

    formatPickerTitleDate(date) {
      return this.formatDateWithMonths(date, "DD MMMM")
    },

    async submitChangeProfileDetails() {
      if (this.isFormValid) {
        const data = {
          first_name: this.credentials.name.value,
          last_name: this.credentials.lastname.value,
          phone_number: (this.credentials.phone?.value || "").replaceAll(" ", ""),
          date_of_birth: this.credentials.date.value,
        }
        try {
          this.isLoading()
          await this.updateProfile(data)
          this.simpleSuccess({ text: this.$t("message.success.editProfile") })
        } catch (_) {
          this.simpleError({ text: this.$t("message.error.updateData") })
          return Promise.reject("failed to update profile data")
        } finally {
          this.isLoaded()
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.title {
  font-weight: 600;
  font-size: 1.25rem;
  color: var(--v-grayscale-darken3);
}

.cancel-button {
  width: 81px !important;
  height: 49px !important;
  font-weight: 600 !important;
  letter-spacing: 0.5px !important;
  text-transform: capitalize;
  border-radius: 8px;
  padding: 0;
  border: 1px solid var(--v-grayscale-lighten4);
  color: var(--v-grayscale-darken3) !important;
}

.input-container {
  position: relative;

  &__label {
    font-weight: 500;
    font-size: 1rem;
    color: var(--v-primary-darken3);
  }

  &__input {
    width: 100%;
    height: 48px;
    padding: 0.75em 0.5rem;
    background: var(--v-primary-lighten2);
    border: 1px solid var(--v-grayscale-lighten4);
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.25);
    border-radius: 8px;

    &--error {
      color: var(--v-error-darken1);
      outline: 2px solid var(--v-error-darken1);
    }

    &:focus {
      outline: 3px solid var(--v-primary-base) !important;
    }
  }

  &__date-picker {
    background: var(--v-primary-lighten2);
    border: 1px solid var(--v-grayscale-lighten4);
    border-radius: 8px;
    height: 48px;
    width: 182px;
    padding: 0 1rem !important;
    display: flex;
    align-items: center;
    font-size: 1rem !important;

    &__button {
      padding: 0 1rem !important;
    }

    ::v-deep input {
      font-size: 1rem !important;
    }
  }
  &__error-message {
    position: absolute;
    bottom: -25px;
    font-size: 0.875rem;
    color: var(--v-error-darken1);
  }
}

.confirm-button {
  min-width: 167px;
}

.v-btn::before {
  display: none !important;
}

::v-deep .v-date-picker-title {
  display: flex;
  flex-direction: row-reverse;
}

::v-deep .v-picker__title__btn {
  color: white !important;
  font-size: 24px;
}

.loader {
  margin: 175px 0;
}
</style>
