<template>
  <div>
    <SharedDialog class="dialog">
      <template #title>
        <p class="dialog__title">{{ messages.addNote }}</p>
      </template>
      <template #content>
        <p class="dialog__description">
          {{ messages.addNoteDescription }}
        </p>
        <Loading v-if="loading" class="mt-10 mb-10"></Loading>
        <textarea
          v-else
          v-model="note"
          maxlength="255"
          solo
          auto-grow
          class="dialog__text-area mb-2"
        ></textarea>
      </template>
      <template #actions="{ closeDialog }">
        <BaseButton
          :disabled="!note || loading"
          class="confirm-button"
          @click="closeDialog(addNote)"
          >{{ messages.addNote }}</BaseButton
        ></template
      >
    </SharedDialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import notyMixin from "@/mixins/notyMixin"
import spinnerMixin from "@/mixins/spinnerMixin"

export default {
  mixins: [notyMixin, spinnerMixin],
  data() {
    return {
      note: "",
      lesson_id: null,
    }
  },
  computed: {
    messages() {
      return {
        addNote: this.$t("message.addNote"),
        addNoteDescription: this.$t("message.addNoteDescription"),
      }
    },
    ...mapGetters("layout", {
      addNoteDialogData: "getDialogConfig",
    }),
  },
  created() {
    const { lesson_id, note } = this.addNoteDialogData?.data
    this.note = note
    this.lesson_id = lesson_id
  },
  methods: {
    ...mapActions("lessons", {
      updateLesson: "updateLesson",
    }),
    async addNote() {
      const data = {
        note: this.note,
        lesson_id: this.lesson_id,
      }
      try {
        this.isLoading()
        await this.updateLesson(data)
        this.simpleSuccess({ text: this.$t("message.success.addNote") })
      } catch (err) {
        this.simpleError({ text: this.$t("message.error.updateData") })
      } finally {
        this.isLoaded()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.dialog {
  &__title {
    font-weight: bold;
    font-size: 1.5rem;
    letter-spacing: 0.02em;
  }

  &__text-area {
    width: 100%;
    height: 94px;
    background-color: #fafbff !important;
    border: 1px solid #eceff9;
    border-radius: 0.5rem;
    resize: none;
    padding: 0.4rem 0.6rem;

    &:focus {
      outline: none;
    }
  }
  &__description {
    font-size: 0.875rem;
    margin: 0.5rem 0 1rem 0 !important;
  }
}
</style>
