<template>
  <div>
    <SharedDialog class="dialog">
      <template #title>
        <div class="d-flex flex-row justify-space-between align-start">
          <h2 class="dialog-title">{{ messages.voiceRecognitionRequestTitle }}</h2>
        </div>
      </template>
      <template #content>
        <p class="dialog-description mt-4">
          {{ messages.voiceRecognitionRequestDescription }}
        </p>
      </template>
      <template #actions="{ closeDialog }">
        <BaseButton class="mt-2" @click="closeDialog(manageVoiceRecognition)">{{
          messages.ok
        }}</BaseButton>
      </template>
    </SharedDialog>
  </div>
</template>

<script>
export default {
  computed: {
    messages() {
      return {
        voiceRecognitionRequestTitle: this.$t("message.voiceRecognitionRequestTitle"),
        voiceRecognitionRequestDescription: this.$t("message.voiceRecognitionRequestDescription"),
        ok: this.$t("message.ok"),
      }
    },
  },
  methods: {
    manageVoiceRecognition() {
      console.log("voiceRecognitionHandler")
    },
  },
}
</script>

<style lang="scss" scoped>
.dialog-title {
  font-size: 1.5rem;
  width: 415px;
}
.dialog-description {
  width: 470px;
}
</style>
