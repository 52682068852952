import { api, instanceAuth } from "@/api"
import router from "@/router"

// Needed only until July 12 to display InformAboutHarmonogramChangeDialog
function isBeforeJuly19EndInPoland() {
  let now = new Date()

  // Calculate the offset for Poland (CEST/UTC+2 during daylight saving time)
  let polandOffset = 2 * 60 // Poland is UTC+2 during daylight saving time

  now.setMinutes(now.getMinutes() + now.getTimezoneOffset() + polandOffset)

  let targetDate = new Date(2024, 6, 19, 23, 59, 0) // Months are 0-indexed, so 6 means July

  return now < targetDate
}

const initialState = {
  email: "",
  password: "",
  resetEmail: "",
  resetPassword: "",
  resetPasswordConfirm: "",
  auth: false,
  profileId: null,
  role: "teacher",
  videoToken: null,
  tutorialActive: false,
}

export default {
  namespaced: true,
  state: () => ({
    ...initialState,
  }),
  getters: {
    getAuth: (state) => state.auth,
    getRole: (state) => state.role,
  },
  mutations: {
    SET_AUTH(state, payload) {
      state.auth = !!payload
    },
    SET_PROFILE_ID(state, payload) {
      state.profileId = payload ?? null
    },
    SET_ROLE(state, payload) {
      state.role = payload ?? "teacher"
    },
    SET_EMAIL(state, payload) {
      state.email = payload
    },
    SET_VIDEO_TOKEN(state, payload) {
      state.videoToken = payload
    },
    SET_PASSWORD(state, payload) {
      state.password = payload
    },
    SET_RESET_EMAIL(state, payload) {
      state.resetEmail = payload
    },
    SET_RESET_PASSWORD(state, payload) {
      state.resetPassword = payload
    },
    SET_RESET_PASSWORD_CONFIRM(state, payload) {
      state.resetPasswordConfirm = payload
    },
    SET_TUTORIAL_ACTIVE(state, payload) {
      state.tutorialActive = payload
    },
    RESET_USER_STATE(state) {
      Object.keys(initialState).forEach((key) => {
        state[key] = initialState[key]
      })
    },
  },
  actions: {
    async authorize({ dispatch }, credentials) {
      try {
        const auth = await api.login(credentials)
        return dispatch("setAuth", auth)
      } catch (err) {
        return Promise.reject(err)
      }
    },
    async setAuth({ dispatch }, payload) {
      if (payload) {
        const { id, role, token } = payload
        this.commit("user/SET_AUTH", payload)
        this.commit("user/SET_PROFILE_ID", id)
        this.commit("user/SET_ROLE", role)
        localStorage.setItem("auth", JSON.stringify(payload))
        localStorage.setItem("role", role)

        if (role === "teacher" && isBeforeJuly19EndInPoland()) {
          setTimeout(() => {
            dispatch(
              "layout/setDialog",
              {
                active: true,
                dialogType: "InformAboutHarmonogramChangeDialog",
              },
              { root: true },
            )
          }, 500)
        }

        instanceAuth.defaults.headers["Authorization"] = token
      } else {
        try {
          if (this.state?.user?.auth) {
            await api.logout()
          }
        } catch (error) {
          console.error(error)
        } finally {
          this.commit("user/RESET_USER_STATE")
          this.commit("profile/RESET_PROFILE_STATE")
          localStorage.removeItem("auth")
          localStorage.removeItem("role")
          localStorage.removeItem("registration_completed")
          router.push("/login")
        }
      }
    },
    logOut({ dispatch }) {
      dispatch("setAuth", null)
      instanceAuth.defaults.headers["Authorization"] = null
      localStorage.removeItem("auth")
      localStorage.removeItem("role")
      localStorage.removeItem("registration_completed")
    },
    async generateVideoToken({ commit }, payload) {
      const { data } = await api.generateToken(payload)
      commit("SET_VIDEO_TOKEN", data.token)
    },
    setRole(_, payload) {
      this.commit("user/SET_ROLE", payload)
      localStorage.setItem("role", payload)
    },
  },
}
