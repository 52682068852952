<!-- eslint-disable vue/no-v-html -->
<template>
  <div>
    <SharedDialog class="dialog" :width="dialogWidth">
      <template #title>
        <p class="dialog__title">{{ title }}</p>
      </template>
      <template #content>
        <Loading v-if="loading" class="my-10"></Loading>
        <template v-else>
          <div v-if="!step" class="d-flex justify-space-between mt-8 mb-10 mr-10 dialog__table">
            <div v-for="column in tableData" :key="column.name" class="d-flex flex-column">
              <p class="dialog__table__header">{{ column.name }}</p>
              <p class="dialog__table__item">{{ transformData(column) }}</p>
            </div>
          </div>
          <div v-if="step">
            <v-alert
              v-if="alertShouldBeVisible"
              class="mt-4"
              dense
              outlined
              :color="isWarned ? 'red darken-2' : 'orange darken-3'"
              v-html="dialogContent"
            />
            <p class="dialog__description">
              {{ messages.description }}
            </p>
            <textarea
              v-model="comment"
              maxlength="255"
              solo
              auto-grow
              class="dialog__text-area mb-2"
            ></textarea>
          </div>
        </template>
      </template>
      <template #actions="{ closeDialog }">
        <div class="d-flex buttons">
          <BaseButton
            class="mr-4 confirm-button cancel-button"
            outlined
            :disabled="cancelBtnDisabled"
            @click="checkCurrentStep(closeDialog)"
            >{{ messages.cancelLesson }}</BaseButton
          >
          <BaseButton
            v-show="joinBtnVisible"
            class="confirm-button"
            :to="redirectLink"
            :disabled="joinBtnDisabled"
            @click="closeDialog"
            >{{ $t("message.join") }}</BaseButton
          >
        </div>
      </template>
    </SharedDialog>
  </div>
</template>

<script>
import getRedirectLink from "@/mixins/getRedirectLink"
import { mapGetters, mapActions, mapState } from "vuex"
import notyMixin from "@/mixins/notyMixin"
import parseDate from "@/mixins/parseDate"
import timeCalculations from "@/mixins/timeCalculations"
import joinBtnAvailabilityMixin from "@/mixins/joinBtnAvailabilityMixin.js"
import disableCancelLessonBtnMixin from "@/mixins/disableCancelLessonBtnMixin"
import spinnerMixin from "@/mixins/spinnerMixin"
import translateLanguage from "@/mixins/translateLanguage"
import { instanceAuth } from "@/api/index"

export default {
  name: "CancelLessonDialog",
  mixins: [
    getRedirectLink,
    notyMixin,
    parseDate,
    joinBtnAvailabilityMixin,
    timeCalculations,
    disableCancelLessonBtnMixin,
    spinnerMixin,
    translateLanguage, // gives: translateLanguageByName()
  ],

  data: () => ({
    step: 0,
    comment: "",
    banDuration: 0,
    cancellationEarliness: 0,
  }),

  computed: {
    ...mapState("user", ["profileId"]),
    ...mapGetters({
      cancelLessonDialogData: "layout/getDialogConfig",
      isWarned: "profile/isWarned",
      userProfile: "profile/getProfile",
    }),
    redirectLink() {
      const { room_token, id, is_learncube } = this.cancelLessonDialogData.data
      return this.getRedirectLink(room_token, id, is_learncube)
    },
    isStudent() {
      return this.userProfile.role === "student"
    },
    dialogContent() {
      const mode = this.isWarned ? "ban" : "warn"
      return this.$t(`banDialog.${mode}.text`)
    },
    dialogWidth() {
      return window.innerWidth <= 520 ? "300" : "600"
    },
    tableData() {
      return [
        {
          name: this.messages.nameAndLastname,
          data: this.cancelLessonDialogData.data.fullName,
          type: "name",
        },
        {
          name: this.messages.learningLanguage,
          data: this.cancelLessonDialogData.data.language,
          type: "language",
        },
        {
          name: this.messages.learningLevel,
          data: this.cancelLessonDialogData.data.level,
          type: "level",
        },
      ]
    },
    title() {
      return this.step ? this.messages.areYouSure : this.messages.lesson
    },
    messages() {
      return {
        lesson: this.$t("message.lesson"),
        nameAndLastname: this.$t("message.profile.name"),
        cancelLesson: this.$t("message.cancelLesson"),
        areYouSure: this.$t("message.cancelLessonDialogTitle"),
        description: this.$t("message.cancelLessonDialogDescription"),
        learningLanguage: this.$t("message.learningLanguage"),
        learningLevel: this.$t("message.learningLevel"),
      }
    },
    joinBtnVisible() {
      return this.step === 0 && this.$route.name === "my-lessons"
    },
    joinBtnDisabled() {
      return this.isJoinDisabled(this.cancelLessonDialogData?.["data"]) || this.cancelBtnDisabled
    },
    cancelBtnDisabled() {
      const { data } = this.cancelLessonDialogData
      return data.canceled || this.disableCancellation(data)
    },
    alertShouldBeVisible() {
      const scheduledAt = this.cancelLessonDialogData.data?.scheduled_at
      const isWarnedCancelation =
        new Date(scheduledAt) - new Date() < this.cancellationEarliness * 3600 * 1000 // hours -> ms
      return this.isStudent && isWarnedCancelation
    },
  },

  async created() {
    window.addEventListener("resize", this.onResize)
    const { data } = await instanceAuth.get("/settings/show")

    this.banDuration = data.ban_duration
    this.cancellationEarliness = data.cancellation_earliness
  },

  destroyed() {
    window.removeEventListener("resize", this.onResize)
  },

  methods: {
    ...mapActions({
      cancelLesson: "lessons/cancelLesson",
      setSnackbar: "snackbar/setSnackbar",
      setDialog: "layout/setDialog",
      logOut: "user/logOut",
      setProfileData: "profile/setProfileData",
    }),
    nextStep() {
      this.step === 1 ? (this.step = 0) : this.step++
    },
    checkCurrentStep(handler) {
      if (!this.step) this.nextStep()
      else {
        const data = {
          lesson_id: this.cancelLessonDialogData.data.lesson_id,
          cancellation_reason: this.comment,
        }
        handler(this.handleCancelLesson(data))
      }
    },
    async handleCancelLesson(data) {
      try {
        this.isLoading()
        await this.cancelLesson(data)
        this.simpleSuccess({ text: this.$t("message.success.cancelLesson") })
        if (this.isWarned && this.alertShouldBeVisible) {
          this.logOut()
        } else {
          this.setProfileData()
        }
      } catch (err) {
        this.simpleError({ text: this.$t("message.error.cancelLesson") })
        console.error(err)
      } finally {
        this.isLoaded()
      }
    },
    transformData(column) {
      if (column.type != "language") return column.data
      else return this.translateLanguageByName(column.data)
    },
    onResize() {
      this.width = window.innerWidth
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/sass/rwd_breakpoints.scss";

.dialog {
  &__title {
    width: 95%;
    font-weight: bold;
    font-size: 1.5rem;
    letter-spacing: 0.02em;
  }

  &__description {
    font-size: 0.875rem;
    margin: 0.5rem 0 1rem 0 !important;
  }

  &__text-area {
    width: 100%;
    height: 94px;
    background-color: #fafbff !important;
    border: 1px solid #eceff9;
    border-radius: 0.5rem;
    resize: none;
    padding: 0.4rem 0.6rem;

    @media (max-width: $bp-m) {
      height: 200px;
    }

    &:focus {
      outline: none;
    }
  }

  &__table {
    flex-direction: row;

    @media (max-width: $bp-m) {
      flex-direction: column;
    }

    &__header {
      font-size: 0.875rem;
      font-weight: 600;
    }

    &__item {
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 40px;
    }
  }
}
.buttons {
  flex-direction: row;

  @media (max-width: $bp-m) {
    flex-direction: column;
  }
}

.cancel-button {
  @media (max-width: $bp-m) {
    margin: 0 0 1rem 0 !important;
  }
}
</style>
