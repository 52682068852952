export default {
  methods: {
    getTranslatedLanguages(items) {
      const locale = this.$i18n.locale

      return items?.map((item) => {
        return {
          value: item.value,
          text: item.locales[locale].nominative,
        }
      })
    },
  },
}
