<template>
  <SharedEditDialog width="740"
    ><template #title>
      <p class="title">{{ messages.editMyLanguages }}</p>
    </template>
    <template #content>
      <Loading v-if="loading" class="loading"></Loading>
      <div v-else class="mt-6 input-container">
        <div class="mb-4 teaching-langiages__input">
          <p class="mb-2 input-label--multiselect">
            {{ messages.whatLanguageDoYouWantToTeachIn }}
          </p>
          <BaseSelect
            v-model="teachingLanguages"
            class="selector__teaching"
            :items="teachingLanguagesOptions"
            multiple
            hide-details
            outlined
            filled
            dense
            solo
            single-line
            :placeholder="messages.chooseLessonTeachingLanguage"
          />
        </div>
        <div v-for="(language, index) in taughtLanguages" :key="index" class="taught">
          <div class="taught__list mb-6 mt-4">
            <div
              v-for="(selector, key, idx) in language"
              :key="selector.key"
              class="taught__group"
              :class="selector.class"
            >
              <p class="mb-2 input-label">{{ selector.label }}</p>
              <div class="d-flex flex-row">
                <BaseSelect
                  v-model="taughtLanguages[index][key].data.value"
                  class="selector__taught"
                  :items="selector.options"
                  hide-details
                  outlined
                  filled
                  solo
                  dense
                  :placeholder="selector.placeholder"
                />
                <button
                  v-if="index != 0 && idx + 1 === Object.keys(language).length"
                  class="ml-4 align-self-center remove-button"
                  @click.prevent="deleteTaughtLanguage(index)"
                >
                  <v-icon size="20">$Close</v-icon>
                </button>
              </div>
            </div>
          </div>
          <v-divider v-if="index + 1 != taughtLanguages.length" class="mr-13"></v-divider>
        </div>
        <BaseButton
          v-if="taughtLanguages.length < languages.length"
          class="language-add d-flex justify-start flex-grow-1 mb-5"
          outlined
          :size-default="false"
          @click="addTaughtLanguage"
          ><v-icon right class="mr-1">$Plus</v-icon>{{ messages.addNewLanguage }}</BaseButton
        >
      </div>
    </template>
    <template #actions="{ closeDialog }">
      <BaseButton
        outlined
        :clear="true"
        color="grayscale-darken3"
        class="mr-4 cancel-button"
        @click="closeDialog"
        >{{ messages.void }}</BaseButton
      >
      <BaseButton
        :disabled="!isFormValid || loading"
        class="confirm-button"
        @click="closeDialog(submitEditExperience)"
        >{{ messages.confirmChanges }}</BaseButton
      >
    </template>
  </SharedEditDialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import { sync } from "vuex-pathify"
import _cloneDeep from "lodash.clonedeep"
import validateInputDefer from "@/mixins/validateInputDefer"
import notyMixin from "@/mixins/notyMixin"
import spinnerMixin from "@/mixins/spinnerMixin"
import translateArrayOfLanguages from "@/mixins/translateArrayOfLanguages"

export default {
  mixins: [validateInputDefer, notyMixin, spinnerMixin, translateArrayOfLanguages], // translateArrayOfLanguages gives: getTranslatedLanguages

  data: () => ({
    teachingLanguages: [],
    taughtLanguages: [],
  }),

  computed: {
    languages: sync("teacher/languages"),
    specializationsOptions: sync("teacher/specializations"),
    levelsOptions: sync("teacher/levels"),

    ...mapGetters("layout", {
      dialogData: "getDialogConfig",
    }),

    isFormValid() {
      let allValues = true
      for (const element of Object.keys(this.taughtLanguages)) {
        if (
          !this.taughtLanguages[element].language.data.value ||
          !this.taughtLanguages[element].level.data.value ||
          !this.taughtLanguages[element].specialization.data.value
        ) {
          allValues = false
        }
      }
      return allValues
    },

    selectedLanguages() {
      return this.taughtLanguages?.map((obj) => String(obj.language.data.value))
    },

    teachingLanguagesOptions() {
      return this.getTranslatedLanguages(this.languages).sort((a, b) =>
        a.text.localeCompare(b.text),
      )
    },

    taughtLanguagesOptions() {
      const languages = this.getTranslatedLanguages(this.languages).sort((a, b) =>
        a.text.localeCompare(b.text),
      )
      return languages
    },

    messages() {
      return {
        editMyExperience: this.$t("message.editMyExperience"),
        whatLanguageDoYouWantToTeachIn: this.$t("message.whatLanguageDoYouWantToTeachIn"),
        whatLanguageDoYouWantToTeach: this.$t("message.whatLanguageDoYouWantToTeach"),
        chooseLessonTeachingLanguage: this.$t("message.chooseLessonTeachingLanguage"),
        atwhatlevel: this.$t("message.atwhatlevel"),
        specialization: this.$t("message.specialization"),
        learningLanguage: this.$t("message.learningLanguage"),
        languageLevel: this.$t("message.languageLevel"),
        addNewLanguage: this.$t("message.addNewLanguage"),
        void: this.$t("message.void"),
        confirmChanges: this.$t("message.confirmChanges"),
        editMyLanguages: this.$t("message.editMyLanguages"),
      }
    },
  },

  async created() {
    this.isLoading()
    await this.setLevels()
    await this.setSpecializations()
    this.isLoaded()

    this.teachingLanguages = this.dialogData.data.teachingLanguages.map((lang) => {
      return lang.id
    })

    if (this.dialogData.data?.taughtLanguages.length) {
      this.taughtLanguages = this.dialogData.data.taughtLanguages.map((lang) => {
        return this.newLanguage(lang)
      })
    } else {
      this.taughtLanguages.push(this.newLanguage())
    }
  },

  methods: {
    ...mapActions("teacher", ["setLevels", "setSpecializations"]),
    ...mapActions("profile", {
      updateLanguages: "updateLanguages",
    }),
    addTaughtLanguage() {
      if (this.taughtLanguages.length === this.languages.length) return
      this.taughtLanguages.push(this.newLanguage())
    },

    deleteTaughtLanguage(index) {
      const _list = _cloneDeep(this.taughtLanguages).filter((_, i) => i != index)
      this.taughtLanguages = _list
    },

    async submitEditExperience() {
      const taught_languages = this.taughtLanguages.map((lang) => {
        return {
          language_id: lang.language.data.value,
          specialization_id: lang.specialization.data.value,
          level_id: lang.level.data.value,
        }
      })

      const data = {
        taught_languages,
        teaching_languages: this.teachingLanguages,
      }

      try {
        this.isLoading()
        await this.updateLanguages(data)
        this.simpleSuccess({ text: this.$t("message.success.editLanguages") })
      } catch (err) {
        this.simpleError({ text: this.$t("message.error.updateData") })
        Promise.reject("failed to update languages")
      } finally {
        this.isLoaded()
      }
    },

    newLanguage(lang) {
      return {
        language: {
          label: this.messages.whatLanguageDoYouWantToTeach,
          placeholder: this.messages.learningLanguage,
          class: "mr-2",
          options: this.taughtLanguagesOptions,
          data: { text: lang?.name || null, value: lang?.id || null },
        },
        level: {
          label: this.messages.atwhatlevel,
          placeholder: this.messages.languageLevel,
          class: "mx-2",
          options: this.levelsOptions,
          data: { text: lang?.level?.name || null, value: lang?.level?.id || null },
        },
        specialization: {
          label: this.messages.specialization,
          placeholder: this.messages.specialization,
          class: "ml-2",
          options: this.specializationsOptions,
          data: {
            text: lang?.specialization?.name || null,
            value: lang?.specialization?.id || null,
          },
        },
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/sass/rwd_breakpoints.scss";
.title {
  font-weight: 600;
  font-size: 1.25rem;
  color: var(--v-grayscale-darken3);
}
.input-label {
  width: 200px;
  font-size: 14px;
  &--multiselect {
    font-size: 14px;
  }
}
.cancel-button {
  width: 81px !important;
  height: 49px !important;
  font-weight: 600 !important;
  letter-spacing: 0.5px !important;
  text-transform: capitalize;
  border-radius: 8px;
  padding: 0;
  border: 1px solid var(--v-grayscale-lighten4);
  color: var(--v-grayscale-darken3) !important;
}
.taught {
  position: relative;

  &__list {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: flex-end;

    @media (max-width: $bp-mt) {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }
  &__group {
    @media (max-width: $bp-mt) {
      margin: 0 0 10px 0 !important;
      width: 90%;
    }
    @media (max-width: $bp-m) {
      width: 80%;
    }
  }
}
.input-container {
  height: 500px;
  overflow: auto;
  &__title {
    font-weight: 500;
    font-size: 16px;
    color: var(--v-primary-darken3);
  }
}

.remove-button {
  @media (max-width: $bp-mt) {
    position: absolute;
    top: -5px;
    right: 15px;
  }
}

.teaching-langiages__input {
  @media (max-width: $bp-mt) {
    width: 90% !important;
  }
}

.selector {
  &__teaching {
    width: 415px;
  }
  &__taught {
    width: 200px;
  }
}
.loading {
  margin: 230px 0;
}
::v-deep .v-select__selections {
  width: 310px !important;
  height: 32px !important;
  width: 100% !important;
  overflow: auto !important;
  display: flex !important;
  flex-direction: row;
}

::v-deep svg {
  height: 20px;
}
</style>
