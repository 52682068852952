<template>
  <!-- Emitted when the input is changed by user interaction
    @event change
    @type {Event} -->
  <v-checkbox
    v-bind="$attrs"
    :ripple="false"
    :hide-details="hideDetails"
    :label="label"
    :input-value="value"
    :disabled="disabled"
    off-icon="$CheckBoxUnmarked"
    on-icon="$CheckBoxMarked"
    @change="$emit('change', $event)"
  ></v-checkbox>
</template>

<script>
/**
 * Component has all props extended from [vuetify](https://vuetifyjs.com/en/api/v-checkbox)
 */
export default {
  name: "BaseCheckboxReg",
  /**
   * Emitted when the input is changed by user interaction
   */
  model: {
    event: "change",
  },
  props: {
    /**
     * Sets input label
     */ label: {
      type: String,
      default: "",
      required: false,
    },
    /**
     * @model
     */
    value: {
      type: [String, Number, Boolean, Array],
      default: "",
      required: true,
    },
    /**
     *
     * Hides hint and validation errors. When set to auto messages will be rendered only if there’s a message (hint, error message, counter value etc) to display. <br /> [vuetify](https://vuetifyjs.com/en/api/v-checkbox/#props-hide-details)
     */
    hideDetails: {
      type: Boolean,
      default: true,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
}
</script>

<style scoped>
::v-deep svg {
  position: absolute;
}
</style>
