import _pick from "lodash.pick"
import decamelize from "decamelize-keys-deep"
import i18n from "@/languages"

import { generateFormData, pipeFunctions, removeFalsy, serializeFormData } from "@/utils/functions"

const PICKED_KEYS = [
  "first_name",
  "phone_number",
  "last_name",
  "currentPassword",
  "passwordConfirmation",
  "description",
  "date_of_birth",
  "teaching_languages",
  "social_links",
  "agreements",
  "sendActivationEmail",
  "removeImage",
  "tutorial_finished",
  "removeVideo",
  "registration_completed",
  "email",
  "sms_notifications_enabled",
  "email_notifications_enabled",
  "commercial_sms_consent",
  "commercial_email_consent",
  "teacher_rules_agreed",
  "user_rules_agreed",
  "voice_recording_agreed",
  "locale",
  "active_until",
]
export const transformDataKeys = (data) => {
  const { taught_languages } = data
  const taughtLanguagesMapped =
    taught_languages?.map((item) => ({
      language_id: item.langId,
      level_id: item.levelId,
      specialization_id: item.specializationId,
    })) ?? []

  return {
    password: data.password,
    taught_languages: taughtLanguagesMapped,
    image: data.picture,
    video: data.video,
    voice_sample: data.voiceSample,
    ...pipeFunctions((obj) => _pick(obj, PICKED_KEYS), decamelize)(data),
  }
}

export const capitalizeFirstLetter = (str) => {
  const lowerCased = str.toLowerCase()
  const capitalized = lowerCased.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
  return capitalized
}

export const transformToFormData = (object) => pipeFunctions(removeFalsy, generateFormData)(object)
export const transformToFormDataSerialize = (object) =>
  pipeFunctions(removeFalsy, serializeFormData)(object)

// TODO: delete this after real agreements are implemented
export const registrationAgreements = {
  user_rules_agreed: {
    checked: false,
    title: i18n.t("agreements.user_rules_agreed.title"),
    description: "",
    req: true,
    type: "all",
  },
  teacher_rules_agreed: {
    checked: false,
    title: i18n.t("agreements.teacher_rules_agreed.title"),
    description: "",
    req: true,
    type: "teacher",
  },
  voice_recording_agreed: {
    checked: false,
    title: i18n.t("agreements.voice_recording_agreed.title"),
    description: i18n.t("agreements.voice_recording_agreed.description"),
    req: true,
    type: "student",
  },
  commercial_sms_consent: {
    checked: false,
    title: i18n.t("agreements.email_notifications_enabled.title"),
    description: i18n.t("agreements.email_notifications_enabled.description"),
    type: "all",
  },
  commercial_email_consent: {
    checked: false,
    title: i18n.t("agreements.sms_notifications_enabled.title"),
    description: i18n.t("agreements.email_notifications_enabled.description"),
    type: "all",
  },
}
