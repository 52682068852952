import { parse, format } from "fecha"

export default {
  computed: {
    months() {
      return {
        ...this.$t("general.months"),
      }
    },
  },
  methods: {
    parseDate(date, time = true) {
      const mask = time ? "YYYY-MM-DD HH:mm" : "YYYY-MM-DD"
      if (!date) return null
      return parse(date, mask)
    },
    formatDate(date) {
      const day = this.datePadding(date.getDate())
      const month = this.datePadding(date.getMonth() + 1)
      const year = date.getFullYear()
      const time = date.toLocaleTimeString().slice(0, -3)

      return `${day}/${month}/${year} ${time}`
    },
    datePadding(d) {
      return d < 10 ? "0" + d.toString() : d.toString()
    },
    getTime: (date) => date.getTime(),
    formatDateWithMonths(date, dateFormat = "MMMM DD, YYYY") {
      return format(new Date(date), dateFormat, { monthNames: Object.values(this.months) })
    },
  },
}
