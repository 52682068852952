import Vue from "vue"
import VueRouter from "vue-router"
import i18n from "@/languages"
import store from "@/store"

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "home",
    redirect: { name: "set-lessons" },
  },
  {
    path: "/login",
    name: "login",
    props: true,
    meta: {
      title: i18n.t("routes.login"),
    },
    component: () => import("../views/LoginView.vue"),
    children: [
      {
        path: ":id",
        name: "login",
        props: true,
        meta: {
          title: i18n.t("routes.loginAsTeacher"),
        },
        component: () => import("../views/LoginView.vue"),
      },
    ],
  },
  {
    path: "/registration",
    name: "registration",
    props: true,
    meta: {
      title: i18n.t("routes.registration"),
    },
    component: () => import("../views/RegistrationView.vue"),
  },
  {
    path: "/dashboard",
    name: "dashboard",
    meta: {
      title: i18n.t("routes.dashboard"),
    },
    redirect: { name: "set-lessons" },
    component: () => import("../views/DashboardView.vue"),
    children: [
      {
        path: "set-lessons",
        name: "set-lessons",
        meta: {
          title: i18n.t("routes.setLessons"),
        },
        component: () => import("@/views/SearchLessonsView.vue"),
      },
      {
        path: "my-lessons",
        name: "my-lessons",
        meta: {
          title: i18n.t("routes.myLessons"),
        },
        component: () => import("../components/MyLessons/MyLessons.vue"),
      },
      {
        path: "it-teachers",
        name: "it-teachers",
        meta: {
          title: i18n.t("routes.itTeachers"),
        },
        component: () => import("@/views/ItTeachersView.vue"),
        beforeEnter: (to, from, next) => {
          if (store.getters["profile/getCompanyName"]?.toLowerCase().includes("inpost")) {
            next()
          } else {
            next("/dashboard/my-lessons")
          }
        },
      },
      {
        path: "profile",
        name: "profile",
        meta: {
          title: i18n.t("routes.profile"),
        },
        component: () => import("../views/ProfileView"),
      },
      {
        path: "student-profile/:id",
        name: "student-profile",
        meta: {
          title: i18n.t("routes.studentProfile"),
        },
        component: () => import("../views/StudentProfileView"),
      },
      {
        path: "video-call-learn-cube",
        name: "lesson-video-call-learn-cube",
        meta: {
          title: i18n.t("routes.videoCall"),
        },
        component: () => import("../views/VideoCall/LearnCube.vue"),
      },
      {
        path: "video-call-vonage",
        name: "lesson-video-call-vonage",
        meta: {
          title: i18n.t("routes.videoCall"),
        },
        component: () => import("../views/VideoCall/Vonage.vue"),
      },
      {
        path: "voice-confirmation",
        name: "voice-confirmation",
        meta: {
          title: i18n.t("routes.voiceConfirmation"),
        },
        component: () => import("../views/VoiceConfirmationView.vue"),
      },
      {
        path: "teacher/:id",
        name: "TeacherSummary",
        meta: {
          title: i18n.t("routes.teacherSummary"),
        },
        component: () => import("../views/TeacherSummaryView.vue"),
      },
    ],
  },

  { path: "/:notFound(.*)", redirect: "/" },
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
})

const allowedRoles = ["teacher", "student"]

router.beforeEach((to, from, next) => {
  const checkAuth = !!localStorage?.getItem("auth")
  const paramsId = to.params.id
  const isTeacher = localStorage?.getItem("role") == "teacher"
  const isActive = localStorage?.getItem("registration_completed") === "true"
  const pathToReg = to.path.startsWith("/registration")
  const pathToLogin = to.path.startsWith("/login")
  const pathFromLogin = from.path.startsWith("/login")

  window.scrollTo(0, 0)

  switch (true) {
    case pathToLogin: {
      const activatedUserPath = isActive ? "/" : "/registration"
      if (!paramsId) {
        next(checkAuth ? activatedUserPath : void 0)
      } else {
        const pathToLogin = allowedRoles.includes(paramsId) ? void 0 : "/login"
        next(checkAuth ? activatedUserPath : pathToLogin)
      }
      break
    }

    case pathToReg:
      next()
      break
    // prevent teacher to access student's list view
    case to.name == "set-lessons" && isTeacher:
      next({ name: "my-lessons" })
      break

    case to.name === "student-profile" && !isTeacher:
      next({ name: "set-lessons" })
      break

    default: {
      const isActiveOrTeacher = isActive || isTeacher
      const activatedUserPath = isActiveOrTeacher || pathFromLogin ? void 0 : "/registration"
      const destination = checkAuth ? activatedUserPath : "/login"

      next(checkAuth ? destination : "/login")
      break
    }
  }
})

export default router
