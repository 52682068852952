import Vue from "vue"
import Vuetify from "vuetify/lib/framework"
import "@mdi/font/css/materialdesignicons.css"
import MessageIcon from "../components/Icons/MessageIcon.vue"
import TimeIcon from "../components/Icons/TimeIcon.vue"
import StarFilled from "../components/Icons/StarFilled.vue"
import ArrowDown from "../components/Icons/ArrowDown.vue"
import ArrowLeft from "../components/Icons/ArrowLeft.vue"
import ArrowRight from "../components/Icons/ArrowRight.vue"
import TogglePassword from "../components/Icons/TogglePassword.vue"
import Info from "../components/Icons/Info.vue"
import Close from "../components/Icons/Close.vue"
import StarEmpty from "../components/Icons/StarEmpty.vue"
import Calendar from "../components/Icons/Calendar.vue"
import Add from "../components/Icons/Add.vue"
import Plus from "@/components/Icons/Plus.vue"
import CheckBoxUnmarked from "@/components/Icons/CheckBoxUnmarked.vue"
import CheckBoxMarked from "@/components/Icons/CheckBoxMarked.vue"
import TogglePasswordError from "@/components/Icons/TogglePasswordError.vue"
import TogglePasswordHidden from "@/components/Icons/TogglePasswordHidden.vue"
import TogglePasswordHiddenError from "@/components/Icons/TogglePasswordHiddenError.vue"
import Camera from "@/components/Icons/Camera.vue"
import SendMessage from "@/components/Icons/SendMessage.vue"

Vue.use(Vuetify)

export const options = {
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: {
          base: "#576596",
          lighten1: "#D8DFF8",
          lighten2: "#FAFBFF",
          darken1: "#3F4B73",
          darken2: "#323C5D",
          darken3: "#11263c",
        },
        grayscale: {
          base: "#83B2E5",
          lighten1: "#ECF5FF",
          lighten2: "#F8FBFF",
          lighten3: "#ffffff",
          lighten4: "#eceff9",
          darken1: "#6284AB",
          darken2: "#3C5169",
          darken3: "#304154",
          darken4: "#d0d6e9",
        },
        teritary: "#455281",
        accent: "#ff747d",
        error: {
          base: "#E8465C",
          lighten1: "#FF9393",
          lighten2: "#FFD5D5",
          darken1: "#BB3748",
          darken2: "#7A0824",
        },
        info: "#2196F3",
        success: {
          base: "#52BA28",
          lighten1: "#AAEA7B",
          lighten2: "#EAFBD4",
          darken1: "#258514",
          darken2: "#095907",
        },
        warning: "#FFC107",
        background: "#f7f9fb",
        black: "#0A0B0B",
      },
    },
  },
  lang: {
    locales: {
      pl: {
        noDataText: "Brak elementów",
      },
      en: {
        noDataText: "No items",
      },
    },
    current: "pl",
  },
  icons: {
    iconfont: "md",
    values: {
      MessageIcon: {
        component: MessageIcon,
      },
      TimeIcon: {
        component: TimeIcon,
      },
      StarFilled: {
        component: StarFilled,
      },
      ArrowDown: {
        component: ArrowDown,
      },
      TogglePassword: {
        component: TogglePassword,
      },
      Info: {
        component: Info,
      },
      Plus: {
        component: Plus,
      },
      Close: {
        component: Close,
      },
      StarEmpty: {
        component: StarEmpty,
      },
      ArrowLeft: {
        component: ArrowLeft,
      },
      ArrowRight: {
        component: ArrowRight,
      },
      Calendar: {
        component: Calendar,
      },
      Add: {
        component: Add,
      },
      CheckBoxUnmarked: {
        component: CheckBoxUnmarked,
      },
      CheckBoxMarked: {
        component: CheckBoxMarked,
      },
      TogglePasswordError: {
        component: TogglePasswordError,
      },
      TogglePasswordHidden: {
        component: TogglePasswordHidden,
      },
      TogglePasswordHiddenError: {
        component: TogglePasswordHiddenError,
      },
      Camera: {
        component: Camera,
      },
      SendMessage: {
        component: SendMessage,
      },
    },
  },
}

export default new Vuetify(options)
