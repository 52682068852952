import { mapGetters, mapState } from "vuex"

export default {
  computed: {
    ...mapState("lessons", ["voiceSampleProvided"]),
    ...mapGetters({ role: "user/getRole", profile: "profile/getProfile" }),
  },
  methods: {
    getRedirectLink(token, lessonId, isLearncube) {
      const { role: userType } = this
      const lessonPath = isLearncube ? "lesson-video-call-learn-cube" : "lesson-video-call-vonage"
      const studentLessonUrl =
        isLearncube || this.voiceSampleProvided ? lessonPath : "voice-confirmation"
      const pathName = userType === "teacher" ? lessonPath : studentLessonUrl
      const userid = this.profile.id
      const email = this.profile.email
      return {
        name: pathName,
        query: {
          token,
          userType,
          userid,
          lessonId,
          email,
          username: `${this.profile.first_name} ${this.profile.last_name}`,
        },
      }
    },
  },
}
