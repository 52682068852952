<template>
  <div>
    <p class="mb-1 input-container__label">{{ name }}</p>

    <v-menu
      ref="dialog"
      v-model="modal"
      :close-on-content-click="false"
      :return-value.sync="valueX"
      persistent
      width="290px"
      transition="scroll-y-transition"
      offset-y
      left
      nudge-left="-20"
      nudge-bottom="15"
      origin="top"
      min-width="auto"
      class="input-container"
    >
      <template #activator="{ on, attrs }">
        <div v-on="on">
          <v-text-field
            :value="formatDisplayDate"
            hide-details
            name="date"
            :placeholder="$t('profile.datePlaceholder')"
            append-icon="$Calendar"
            readonly
            dense
            v-bind="attrs"
            class="date-picker"
            v-on="on"
          ></v-text-field>
        </div>
      </template>
      <v-date-picker
        :value="formatInputDate"
        :allowed-dates="allowedDates"
        header-color="#3c486f"
        color="#3c486f"
        flat
        :title-date-format="formatPickerTitleDate"
        :max="maxDate"
        @change="dateChange"
        @click:date="updateBirthDate(valueX)"
      >
        <v-spacer />
      </v-date-picker>
    </v-menu>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex"
import { converDateToSlashFormat } from "@/utils/functions"
import parseDate from "@/mixins/parseDate"
import { format } from "fecha"

export default {
  name: "BaseDatePicker",
  mixins: [parseDate],

  props: {
    name: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    modal: false,
    valueX: "",
  }),
  computed: {
    ...mapGetters("profile", {
      dateOfBirth: "getDateOfBirth",
    }),
    birthDate() {
      return new Date(this.dateOfBirth)
    },
    formatDisplayDate() {
      return this.dateOfBirth ? converDateToSlashFormat(this.dateOfBirth) : ""
    },
    formatInputDate() {
      return this.dateOfBirth
        ? this.birthDate.toLocaleDateString("en-CA", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          })
        : ""
    },
    maxDate() {
      return format(new Date(), "YYYY-MM-DD")
    },
  },
  watch: {
    value(newValue) {
      this.valueX = newValue
    },
  },
  methods: {
    ...mapMutations("profile", {
      setBirthDate: "SET_DATE_OF_BIRTH",
    }),
    allowedDates(val) {
      return new Date(val) < new Date()
    },
    formatPickerTitleDate(date) {
      return this.formatDateWithMonths(date, "DD MMMM")
    },
    dateChange(date) {
      this.valueX = date
    },
    updateBirthDate(value) {
      this.setBirthDate(value)
      this.modal = false
    },
  },
}
</script>

<style lang="scss" scoped>
.date-picker {
  display: flex;
  align-items: center;
  height: 3rem;
  padding: 0 1rem !important;
  background-color: #fafbff;
  border-radius: 0.5rem;
  border-width: thin;
  border-style: solid;
  border-color: #e6e6e6aa;
}

::v-deep .v-date-picker-title {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;

  &__year {
    margin: 0 10px;
  }
}

::v-deep .v-picker__title__btn {
  color: white !important;
  font-size: 24px;
}
</style>
