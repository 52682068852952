<template>
  <div>
    <div class="mb-1">{{ label }}</div>
    <ValidationProvider
      ref="validateProvider"
      v-slot="{ errors }"
      :mode="validationMode"
      :name="name"
      :rules="rules"
    >
      <vue-tel-input-vuetify
        v-bind="$attrs"
        v-model="model"
        :error-messages="errors"
        label=""
        :placeholder="placeholder"
        background-color="#fafbff"
        disabled-fetching-country
        default-country="pl"
        dense
        height="48"
        solo
        single-line
        autocomplete="off"
        :input-options="{ showDialCode: false, tabindex: 0 }"
        mode="national"
        @country-changed="$emit('set-country-code', $event)"
        @input="$emit('input', $event)"
        @focus="resetValidateProvider"
      ></vue-tel-input-vuetify>
    </ValidationProvider>
  </div>
</template>

<script>
import { ValidationProvider } from "vee-validate"

export default {
  components: { ValidationProvider },

  props: {
    value: {
      type: String,
      default: "",
      required: true,
    },
    label: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    validationMode: {
      type: String,
      default: "lazy",
    },
    id: {
      type: String,
      default: "",
    },
    rules: {
      type: String,
      default: "",
    },
    outlined: {
      type: Boolean,
      default: true,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      model: "",
    }
  },
  watch: {
    model: {
      immediate: true,
      handler(value) {
        this.model = value || this.value
      },
    },
  },
  methods: {
    resetValidateProvider() {
      this.$refs["validateProvider"].reset()
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep {
  .vue-tel-input-vuetify .country-code {
    width: 60px !important;
  }
  .v-text-field__details {
    display: none;
  }
}
</style>
