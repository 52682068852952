import store from "@/store"
import spreadResponse from "@/utils/spreadResponse"
import { registrationAgreements } from "@/utils/profileUtils"

const path = {
  me: {
    student: "/students/me",
    teacher: "/teachers/me",
  },
  registration: "/teachers/sign_up",
  update: { student: "/students/update", teacher: "/teachers/update" },
  list: {
    languages: "/languages/list",
    specializations: "/specializations/list",
    levels: "/levels/list",
  },
  agreements: "/agreements",
}

export default {
  getProfileData: async (axios) => {
    const role = store.getters["user/getRole"]
    const { data } = await axios.get(path.me[role])

    return data
  },
  registerTeacher: async (axios, payload) => {
    const { data } = await axios.post(path.registration, payload)

    return data
  },

  updateProfile: async (axios, data) => {
    const role = store.getters["user/getRole"]
    try {
      const res = await axios.post(path.update[role], data, {
        "Content-Type": "multipart/form-data",
      })
      return spreadResponse(res)
    } catch (err) {
      console.error(err)
      throw new Error("Failed to update profile")
    }
  },
  getLanguages: async (axios) => {
    const { data } = await axios.get(path.list.languages)

    return data
  },
  getSpecializations: async (axios) => {
    const { data } = await axios.get(path.list.specializations)

    return data
  },
  getLevels: async (axios) => {
    const { data } = await axios.get(path.list.levels)

    return data
  },
  fetchAgreements: async () => {
    return registrationAgreements
  },
  fetchSettings: async (axios) => {
    const { data } = await axios.get("/settings/show")

    return data
  },
}
