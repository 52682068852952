const getNavigatorLanguage = () => {
  if (navigator.languages && navigator.languages.length) {
    return navigator.languages[0]
  } else {
    return navigator.userLanguage || navigator.language || navigator.browserLanguage || "en"
  }
}
const lang = getNavigatorLanguage()

export const navigatorLanguage = lang.length > 2 ? lang.substring(0, 2) : lang
