<template>
  <div>
    <Component :is="dialog"></Component>
  </div>
</template>

<script>
import AddNoteDialog from "@/components/Dialogs/AddNoteDialog.vue"
import VoiceRecognitionDialog from "@/components/Dialogs/VoiceRecognitionDialog.vue"
import RateLessonDialog from "@/components/Dialogs/RateLessonDialog"
import CancelLessonDialog from "@/components/Dialogs/CancelLessonDialog"
import EditMyProfileDetailsDialog from "@/components/Dialogs/EditMyProfileDetailsDialog.vue"
import EditPasswordDialog from "@/components/Dialogs/EditPasswordDialog.vue"
import EditDescriptionDialog from "@/components/Dialogs/EditDescriptionDialog.vue"
import EditSocialMediaDialog from "@/components/Dialogs/EditSocialMediaDialog.vue"
import PreviewDialog from "@/components/Dialogs/PreviewDialog.vue"
import SetLessonDialog from "@/components/Dialogs/SetLessonDialog.vue"
import ScheduleConfirmLessons from "@/components/Dialogs/ScheduleConfirmLessons.vue"
import VoiceConfirmationDialog from "@/components/Dialogs/VoiceConfirmationDialog.vue"
import WorkScheduleConfirmDialog from "@/components/Dialogs/WorkScheduleConfirmDialog.vue"
import RegistrationCompleteDialog from "@/components/Dialogs/RegistrationCompleteDialog.vue"
import EditLanguagesDialog from "@/components/Dialogs/EditLanguagesDialog.vue"
import InformAboutHarmonogramChangeDialog from "./InformAboutHarmonogramChangeDialog.vue"

export default {
  name: "DialogsIndex",
  computed: {
    dialogType() {
      const activeDialog = this.$store.getters["layout/getDialogConfig"]
      return activeDialog.dialogType
    },
    dialog() {
      return {
        AddNoteDialog,
        VoiceRecognitionDialog,
        RateLessonDialog,
        CancelLessonDialog,
        EditMyProfileDetailsDialog,
        EditPasswordDialog,
        EditDescriptionDialog,
        EditSocialMediaDialog,
        PreviewDialog,
        SetLessonDialog,
        ScheduleConfirmLessons,
        VoiceConfirmationDialog,
        WorkScheduleConfirmDialog,
        RegistrationCompleteDialog,
        EditLanguagesDialog,
        InformAboutHarmonogramChangeDialog,
      }[this.dialogType]
    },
  },
}
</script>
