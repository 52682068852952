<template>
  <div class="text-center">
    <v-menu offset-y>
      <template #activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          :ripple="false"
          plain
          tile
          class="language"
          active-class="no-active"
          v-on="on"
        >
          {{ currentLanguage }}
        </v-btn>
      </template>
      <v-list-item-group>
        <v-list v-if="languages.length > 0" class="d-flex flex-column px-2 mt-5 menu-container">
          <div v-for="(item, index) in languages" :key="index">
            <v-divider v-show="index !== 0" class="mx-4"></v-divider
            ><v-list-item
              :v-hover="false"
              :ripple="false"
              @click="$emit('change-language', item.title)"
            >
              <v-list-item-title class="my-4">{{ item.title }}</v-list-item-title>
            </v-list-item>
          </div>
        </v-list>
      </v-list-item-group>
    </v-menu>
  </div>
</template>

<script>
export default {
  computed: {
    languages() {
      const otherLanguages = Object.keys(this.$i18n.messages).filter((val) => {
        return val != this.$i18n.locale
      })
      return otherLanguages.map((val) => {
        return {
          title: val.toUpperCase(),
        }
      })
    },
    currentLanguage() {
      return String(this.$i18n.locale).toUpperCase()
    },
  },
}
</script>

<style lang="scss" scoped>
.language {
  text-transform: capitalize;
  color: black !important;
  width: 60px !important;
  font-weight: bold;
}
.menu-container {
  min-width: 60px !important;
}
.v-list-item {
  min-height: auto;
  display: flex;
  align-items: flex-start;

  &__title {
    font-size: 14px;
  }
}

.v-sheet.v-list:not(.v-sheet--outlined) {
  box-shadow: none !important;
}
.v-menu__content {
  box-shadow: none !important;
}

.v-list-item .v-list-item__title,
.v-list-item .v-list-item__subtitle {
  line-height: 1.5;
}

.v-list-item--link::before {
  background-color: transparent !important;
}
</style>
