<template>
  <div>
    <v-dialog v-model="dialog" persistent v-bind="$attrs" :width="width">
      <v-card class="dialog-container">
        <v-icon class="dialog-container__close-icon" size="19" @click="closeDialog">$Close</v-icon>

        <div class="dialog-container__text-content">
          <slot name="title">
            <h2 class="dialog__title">{{ title }}</h2>
          </slot>
          <slot name="content"></slot>
        </div>

        <v-card-actions v-if="!hideConfirmButton" class="mt-4">
          <v-spacer class="spacer"></v-spacer>
          <slot name="actions" :close-dialog="closeDialog">
            <BaseButton @click="closeDialog(closeClb)" v-text="closeBtnTitle" />
          </slot>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex"

export default {
  name: "SharedDialog",
  props: {
    title: {
      type: String,
      default: "",
    },
    closeBtnTitle: {
      type: String,
      default: "",
    },
    closeClb: {
      type: Function,
      default: () => {},
    },
    hideConfirmButton: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: "600",
    },
  },
  data() {
    return {
      dialog: true,
    }
  },
  watch: {
    "$route.path"() {
      this.closeDialog()
    },
  },
  mounted() {
    document.addEventListener("keydown", this.closeOnEscape)
  },
  destroyed() {
    document.removeEventListener("keydown", this.closeOnEscape)
  },
  methods: {
    ...mapActions("layout", {
      setDialog: "setDialog",
    }),
    async closeDialog(handler) {
      this.$emit("close-dialog")
      if (typeof handler === "function") {
        try {
          await handler()
          this.dialog = false
          this.setDialog({ active: false, data: null, dialogType: "" })
        } catch (err) {
          console.error(err)
        }
      }
      this.dialog = false
      this.setDialog({ active: false, data: null, dialogType: "" })
    },
    closeOnEscape(ev) {
      if (ev.keyCode === 27) {
        this.closeDialog()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/sass/rwd_breakpoints.scss";

::v-deep .dialog__title {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  /* identical to box height */

  letter-spacing: 0.02em;

  color: var(--v-primary-darken3);
}

.dialog-container {
  padding: 2rem;
  border-radius: 8px;
  background-color: #ffffff;
  overflow-y: auto;
  position: relative;

  &__close-icon {
    position: absolute !important;
    top: 0;
    right: 0;
    margin: 2rem 2rem 0 0;
    cursor: pointer;
  }

  &__text-content {
    margin: 0 !important;
  }
}
.spacer {
  display: inline;

  @media (max-width: $bp-m) {
    display: none;
  }
}
</style>
