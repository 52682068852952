<template>
  <v-dialog v-model="dialog" persistent :width="width" @keydown.esc="closeDialog">
    <v-card class="dialog-container">
      <v-icon class="dialog-container__close-icon" @click="closeDialog">$Close</v-icon>

      <div class="dialog-container__content">
        <slot name="title"></slot>
        <slot name="content"></slot>
      </div>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>

        <div class="dialog-container__actions">
          <slot name="actions" :close-dialog="closeDialog"></slot>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex"

export default {
  props: {
    width: {
      type: String,
      default: "430",
    },
  },
  data() {
    return {
      dialog: true,
    }
  },
  watch: {
    "$route.path"() {
      this.closeDialog()
    },
  },
  methods: {
    async closeDialog(handler) {
      if (typeof handler === "function") {
        try {
          await handler()
          this.dialog = false
          this.setDialog({ active: false, data: null, dialogType: "" })
        } catch (err) {
          console.error(err)
        }
      } else {
        this.dialog = false
        this.setDialog({ active: false, data: null, dialogType: "" })
      }
    },
    ...mapActions("layout", {
      setDialog: "setDialog",
    }),
  },
}
</script>

<style lang="scss" scoped>
.dialog-container {
  border-radius: 8px;
  background-color: #ffffff;
  position: relative;
  overflow-y: scroll;

  &__close-icon {
    position: absolute !important;
    top: 0;
    right: 0;
    width: 18px;
    height: 18px;
    margin: 1.2rem 1.2rem 0 0;
    cursor: pointer;
  }

  &__content {
    padding: 1.2rem 1.5rem 3em 1.5rem;
    margin: 0 !important;
  }

  &__actions {
    padding: 1.5rem;
  }
}
</style>
