<template>
  <div>
    <SharedDialog class="dialog" @close-dialog="routeToLogin">
      <template #title>
        <p class="dialog__title">{{ $t("registration.thanksTitle") }}</p>
      </template>
      <template #content>
        <div>
          <p class="dialog__description">
            {{ $t("registration.thanksSubitle") }}
          </p>
        </div>
      </template>
      <template #actions="{ closeDialog }">
        <BaseButton @click="closeDialog(routeToLogin)">{{ $t("message.ok") }}</BaseButton></template
      >
    </SharedDialog>
  </div>
</template>

<script>
import { mapActions, mapMutations } from "vuex"

export default {
  methods: {
    ...mapActions("user", ["logOut"]),
    ...mapMutations({
      setAgreements: "profile/SET_AGREEMENTS",
    }),
    routeToLogin() {
      this.setAgreements({})
      this.logOut()
      this.$router.push({ path: "/login/teacher" })
    },
  },
}
</script>

<style lang="scss" scoped>
.dialog {
  &__title {
    width: 95%;
    font-weight: bold;
    font-size: 1.5rem;
    letter-spacing: 0.02em;
  }

  &__description {
    font-size: 0.875rem;
    margin: 1rem 0 1.5rem 0 !important;
    width: 95%;
  }
}
</style>
