<template>
  <div class="shared-info-card">
    <div class="shared-info-card__top d-flex flex-row justify-space-between">
      <slot name="title">
        <p v-text="title"></p>
      </slot>
      <slot name="switcher"></slot>
    </div>

    <v-divider></v-divider>
    <div class="shared-info-card__content" :class="{ overflow }">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "SharedInfoCard",
  props: {
    title: {
      type: String,
      default: "",
      required: false,
    },
    overflow: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.shared-info-card {
  background-color: var(--v-grayscale-lighten3);
  border: 1px solid var(--v-grayscale-lighten4);
  box-sizing: border-box;
  border-radius: 8px;

  &__top {
    font-size: 1rem;
    font-weight: 700;
    color: var(--v-grayscale-darken3);
    padding: 1.75rem;
  }

  &__content {
    padding: 1.5rem;

    &.overflow {
      overflow-x: scroll;
    }
  }
}
</style>
