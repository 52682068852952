<template>
  <v-select
    :class="defaultStyles && 'save-default'"
    v-bind="$attrs"
    :value="value"
    :menu-props="{ bottom: true, offsetY: true }"
    :item-text="itemText"
    :item-value="itemValue"
    :append-icon="appendIcon"
    @change="$emit('change', $event)"
  ></v-select>
</template>

<script>
export default {
  name: "BaseSelect",
  model: {
    event: "change",
  },
  props: {
    value: {
      type: [String, Number, Array],
      default: "",
    },
    itemText: {
      type: String,
      default: "text",
    },
    itemValue: {
      type: String,
      default: "value",
    },
    appendIcon: {
      type: String,
      default: "$ArrowDown",
    },
    defaultStyles: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
