<template>
  <div class="text-center">
    <v-menu offset-y>
      <template #activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          :ripple="false"
          plain
          tile
          class="user-name"
          active-class="no-active"
          v-on="on"
        >
          {{ getFullName(name, lastName) }}
        </v-btn>
      </template>
      <v-list-item-group>
        <v-list class="d-flex flex-column px-2 mt-5">
          <div v-for="(item, index) in items" :key="index">
            <v-divider v-show="index !== 0" class="mx-4"></v-divider
            ><v-list-item :v-hover="false" :ripple="false" @click="item.handler()">
              <v-list-item-title class="my-4">{{ item.title }}</v-list-item-title>
            </v-list-item>
          </div>
        </v-list>
      </v-list-item-group>
    </v-menu>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import getFullName from "@/mixins/getFullName"

export default {
  mixins: [getFullName],
  props: {
    items: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters("profile", {
      name: "getName",
      lastName: "getLastName",
    }),
  },
}
</script>

<style lang="scss" scoped>
.user-name {
  text-transform: capitalize;
  color: black !important;
  font-size: 1rem;
  letter-spacing: 0.01em;
}
.v-list-item {
  min-height: auto;
  display: flex;
  align-items: flex-start;

  &__title {
    font-size: 14px;
  }
}

.v-sheet.v-list:not(.v-sheet--outlined) {
  box-shadow: none !important;
}
.v-menu__content {
  box-shadow: none !important;
}

.v-list-item .v-list-item__title,
.v-list-item .v-list-item__subtitle {
  line-height: 1.5;
}

.v-list-item--link::before {
  background-color: transparent !important;
}
</style>
