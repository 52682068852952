import getDateNowInPoland from "@/mixins/getDateNowInPoland"

export default {
  mixins: [getDateNowInPoland],
  methods: {
    disableCancellation(item) {
      const scheduledDate =
        typeof item.scheduled_at === "object" ? item.scheduled_at : new Date(item.scheduled_at) // checks if it's a date object
      const finishedDate = item?.finished_at // if finished date is set cancel button should be always disabled
      return finishedDate || this.getDateNowInPoland() >= scheduledDate
    },
  },
}
