<template>
  <div class="harmonogram__days d-flex flex-row flex-wrap">
    <v-chip
      v-for="(lesson, i) of selected"
      :key="i"
      class="ma-2"
      close
      @click="$emit('pick')"
      @click:close="deselectSlot(i)"
    >
      {{ getLessonFullTime(lesson) }}
    </v-chip>
  </div>
</template>

<script>
import { mapActions } from "vuex"
export default {
  name: "SelectLessons",
  props: {
    selected: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  computed: {
    locale() {
      return this.$i18n?.locale ?? "en"
    },
  },
  methods: {
    ...mapActions("lessons", ["selectLesson"]),
    getLessonFullTime(slot) {
      const day = new Date(slot.day).toLocaleDateString(this.locale, {
        weekday: "short",
        day: "numeric",
      })
      const time = slot.time
      return `${day} - ${time}`
    },
    deselectSlot(id) {
      this.selectLesson({ id })
    },
  },
}
</script>
