<template>
  <SharedDialog class="dialog">
    <template #title>
      <h2 class="dialog__title mb-3">{{ messages.title }}</h2>
      <p class="dialog__subtitle mb-5">{{ messages.subtitle }}</p>
    </template>
    <template #actions="{ closeDialog }">
      <BaseButton @click="closeDialog(() => dialogConfig.data.handler())">{{
        messages.confirm
      }}</BaseButton></template
    ></SharedDialog
  >
</template>

<script>
import { mapGetters } from "vuex"

export default {
  name: "WorkScheduleConfirmDialog",
  computed: {
    ...mapGetters("layout", {
      dialogConfig: "getDialogConfig",
    }),
    messages() {
      return {
        title: this.$t("message.areYouSure"),
        subtitle: this.$t("message.changeWorkScheduleLessonCancel"),
        confirm: this.$t("message.confirm"),
      }
    },
  },
}
</script>
