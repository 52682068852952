export default {
  namespaced: true,
  state: () => ({
    activeMenu: false,
    dialog: {
      active: false,
      dialogType: "",
      data: null,
    },
    showHeader: false,
  }),
  mutations: {
    SET_DIALOG(state, setup) {
      state.dialog = setup
    },
    SET_SHOW_HEADER(state, showHeader) {
      state.showHeader = showHeader
    },
    SET_MENU_ACTIVE(state, activeMenu) {
      state.activeMenu = activeMenu
    },
  },
  actions: {
    setDialog({ commit }, setup) {
      commit("SET_DIALOG", setup)
    },
  },
  getters: {
    getDialogConfig(state) {
      return state.dialog
    },
    getShowHeader(state) {
      return state.showHeader
    },
  },
}
