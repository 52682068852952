import spreadResponse from "@/utils/spreadResponse"

const path = {
  login: "/users/sign_in",
  logout: "/users/sign_out",
  invite: "/students/accept_invitation",
  resetPassword: "/users/reset_password",
  updatePassword: "/users/update_password",
  validateResetToken: "/users/validate_reset_token",
  validateEmailAvailability: "/users/validate_email",
}

export default {
  logIn: async (axios, credentials) => {
    const { email, password, remember } = credentials
    const res = await axios.post(path.login, {
      email,
      password,
      remember,
    })

    return spreadResponse(res)
  },
  logOut: async (axios) => {
    await axios.post(path.logout)
  },
  acceptInvitation: async (axios, invitation_token) => {
    const res = await axios.post(path.invite, {
      invitation_token,
    })

    return spreadResponse(res)
  },
  resetPassword: async (axios, email) => {
    const res = await axios.post(path.resetPassword, {
      email,
    })

    return spreadResponse(res)
  },
  updatePassword: async (axios, { reset_token, password, password_confirmation }) => {
    const res = await axios.post(path.updatePassword, {
      reset_token,
      password,
      password_confirmation,
    })

    return spreadResponse(res)
  },

  validateResetToken: async (axios, reset_token) => {
    const res = await axios.post(path.validateResetToken, {
      reset_token,
    })

    return spreadResponse(res)
  },

  validateEmailAvailability: async (axios, email) => {
    const res = await axios.post(path.validateEmailAvailability, {
      email,
    })

    return spreadResponse(res)
  },
}
