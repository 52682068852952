<template>
  <!-- Emitted when the input is changed by user interaction
    @event input
    @type {Event} -->

  <ValidationProvider
    ref="validateProvider"
    v-slot="{ errors }"
    :mode="validationMode"
    :name="name"
    :rules="rules"
  >
    <p v-if="label" class="mb-1 input-label">{{ label }}</p>
    <v-text-field
      v-bind="$attrs"
      :id="id"
      v-model="model"
      height="48"
      :error-messages="errors"
      :placeholder="placeholder"
      :outlined="outlined"
      :hide-details="hideDetails"
      :append-icon="generateIcon(errors)"
      :type="show ? 'text' : 'password'"
      class="input"
      @focus="onFocus"
      @click:append="showPass = !showPass"
      @input="$emit('input', $event)"
      @blur="$emit('blur', $event)"
    ></v-text-field>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from "vee-validate"

/**
 * Component has all props extended from [vuetify](https://vuetifyjs.com/en/api/v-checkbox)
 */
export default {
  name: "BaseInput",
  components: { ValidationProvider },
  props: {
    /**
     * @model
     */
    value: {
      type: String,
      default: "",
      required: true,
    },
    label: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
    validationMode: {
      type: String,
      default: "lazy",
    },
    placeholder: {
      type: String,
      default: "",
    },
    /**
     * "validPassword" prop is needed when the input is type password.
     * It is used to change toggle icon (red -invalid, black - valid).
     * We can't use "valid" prop, because it gives us state of whole form, not just password input.
     */
    validPassword: {
      type: Boolean,
      default: true,
    },
    /**
     *Accepts a mixed array of types function, boolean and string. Functions pass an input value as an argument and must return either true / false or a string containing an error message. The input field will enter an error state if a function returns (or any value in the array contains) false or is a string <br /> [vuetify](https://vuetifyjs.com/en/api/v-input/#props-rules)
     */
    rules: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    /**
     * Applies the outlined style to the input <br /> [vuetify](https://vuetifyjs.com/en/api/v-text-field/#props-outlined)
     */
    outlined: {
      type: Boolean,
      default: true,
    },
    /**
     * Hides hint and validation errors. When set to auto messages will be rendered only if there’s a message (hint, error message, counter value etc) to display <br /> [vuetify](https://vuetifyjs.com/en/api/v-text-field/#props-hide-details)
     */
    hideDetails: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showPass: false,
      model: "",
    }
  },
  computed: {
    show() {
      return this.type === "password" ? this.showPass : true
    },
  },
  watch: {
    model: {
      immediate: true,
      handler(value) {
        this.model = value || this.value
      },
    },
  },
  methods: {
    generateIcon(errors) {
      if (this.type !== "password") return null

      let icon = `$TogglePassword${this.show ? "Hidden" : ""}`

      if (errors.length) {
        icon = `$TogglePassword${this.show ? "HiddenError" : "Error"}`
      }

      return icon
    },
    onFocus() {
      this.$refs["validateProvider"].reset()
    },
  },
}
</script>

<style lang="scss" scoped>
.input {
  border-radius: 8px;
}
</style>
