const ONE_HOUR = 60 * 60 * 1000
const ONE_DAY = ONE_HOUR * 24
const RANGE_OFFSET = 7 * 24 * ONE_HOUR
import { format } from "fecha"

export default {
  data() {
    return {
      week: [],
      // range between current week and the last active week
      // currently 7 days
    }
  },
  created() {
    this.week = this.currentWeek()
  },
  computed: {
    months() {
      return {
        ...this.$t("general.months"),
      }
    },
    locale() {
      return this.$i18n?.locale ?? "en"
    },
    firstDayOfWeek() {
      return this.week.at(0) ?? this.getFirstDayOfWeek()
    },
    lastDayOfWeek() {
      return this.week.at(-1) ?? this.getFirstDayOfWeek() + 6
    },
    isFirstWeek() {
      const date = new Date().setHours(0, 0, 0, 0)
      const firstDayOfWeek = this.firstDayOfWeek.setHours(0, 0, 0, 0)
      const lastDayOfWeek = this.lastDayOfWeek.setHours(0, 0, 0, 0)
      return date >= firstDayOfWeek && date <= lastDayOfWeek
    },
    isLastWeek() {
      const date = new Date(new Date().getTime() + RANGE_OFFSET).setHours(0, 0, 0, 0)
      const firstDayOfWeek = this.firstDayOfWeek.setHours(0, 0, 0, 0)
      const lastDayOfWeek = this.lastDayOfWeek.setHours(0, 0, 0, 0)
      return date >= firstDayOfWeek && date <= lastDayOfWeek
    },
  },
  methods: {
    getWeekRange() {
      const first = new Date(this.firstDayOfWeek)
      const last = new Date(this.lastDayOfWeek)
      const sameMonth = first.getMonth() === last.getMonth()

      return `${format(new Date(first), sameMonth ? "D" : "D MMMM", {
        monthNames: Object.values(this.months),
      })} - ${format(new Date(last), "D MMMM", { monthNames: Object.values(this.months) })}`
    },
    currentWeek(time = new Date()) {
      const date = typeof time === "object" ? time : new Date(time)
      const weekStartDay = this.getFirstDayOfWeek(date)
      const weekStartDate = new Date(date.setDate(weekStartDay))
      const weekEndDate = new Date(+weekStartDate + ONE_DAY * 7) // add seven days to week start date
      const week = []
      let i = weekStartDate
      while (i < weekEndDate) {
        week.push(i)
        i = new Date(+i + ONE_DAY)
      }
      return week
    },
    getFirstDayOfWeek(time = new Date()) {
      let day = time.getDay()
      let diff = time.getDate() - day + (day === 0 ? -6 : 1) // adjust when day is Sunday
      return new Date(time.setDate(diff)).getDate()
    },
    getNextWeek() {
      const first = new Date(this.firstDayOfWeek)
      this.week = this.currentWeek(first.setDate(first.getDate() + 7))
    },
    getPrevWeek() {
      const first = new Date(this.firstDayOfWeek)
      this.week = this.currentWeek(first.setDate(first.getDate() - 7))
    },
  },
}
