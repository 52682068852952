import { extend, configure } from "vee-validate"
import i18n from "./languages/index"

import * as rules from "vee-validate/dist/rules"

// install rules and localization
Object.keys(rules).forEach((rule) => {
  extend(rule, rules[rule])
})

configure({
  defaultMessage: (field, values) => {
    // override the field name.
    values._field_ = i18n.t(`fields.${field}`)

    return i18n.t(`validation.${values._rule_}`, values)
  },
})
