import Vue from "vue"

export default {
  namespaced: true,
  state: () => ({
    tables: {
      scheduledLessons: {
        items: [],
      },
      closedLessons: {
        items: [],
      },
    },
  }),
  mutations: {
    setRating(state, data) {
      const closedLessons = state.tables.closedLessons.items
      const lessonIndex = closedLessons.findIndex((obj) => obj.id === data.id)
      Vue.set(closedLessons[lessonIndex], "rating", {
        rate: data.rate,
        comment: data.comment,
      })
    },
  },
  actions: {
    setRating({ commit }, data) {
      commit("setRating", data)
    },
  },
}
