export default {
  methods: {
    parseAndFormatDate(data) {
      if (data) return this.formatDate(this.parseDate(data))
    },
    // TODO extract this function to a external helper
    getName(teacher) {
      return `${teacher?.first_name || ""} ${teacher?.last_name || ""}`
    },
    resolveImgUrl: function (path) {
      let images = require.context("@/assets/", false, /\.png$|\.jpg|\.svg$/)
      return path ? images("./" + path) : ""
    },
  },
}
