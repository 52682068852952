<template>
  <SharedEditDialog
    ><template #title>
      <p class="title">{{ messages.editMyDescription }}</p>
    </template>
    <template #content>
      <div class="mt-6 input-container">
        <p class="mb-1 input-container__title">{{ messages.description }}</p>
        <textarea
          v-model="credentials.description.value"
          maxlength="1000"
          :placeholder="messages.describeYourExperience"
          class="input-container__text-area"
          :class="{ 'input-container__text-area--error': credentials.description.err }"
          @blur="validateInputDefer($event, 'description', 0)"
        ></textarea>
        <p v-if="credentials.description.err" class="input-container__error-message">
          {{ messages.error.description }}
        </p>
      </div>
    </template>
    <template #actions="{ closeDialog }">
      <BaseButton
        outlined
        :clear="true"
        color="grayscale-darken3"
        class="mr-4 cancel-button"
        @click="closeDialog"
        >{{ messages.void }}</BaseButton
      >
      <BaseButton
        :disabled="!isFormValid"
        class="confirm-button"
        @click="closeDialog(submitEditExperience)"
        >{{ messages.confirmChanges }}</BaseButton
      >
    </template>
  </SharedEditDialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import validateInputDefer from "@/mixins/validateInputDefer"
import notyMixin from "@/mixins/notyMixin"
import spinnerMixin from "@/mixins/spinnerMixin"

export default {
  mixins: [validateInputDefer, notyMixin, spinnerMixin],

  data: () => ({
    credentials: {
      description: {
        value: "",
        err: false,
        timeout: null,
      },
    },
  }),

  computed: {
    ...mapGetters("layout", {
      dialogData: "getDialogConfig",
    }),

    isFormValid() {
      let allValues = true
      let anyErrors = false

      for (const element of Object.keys(this.credentials)) {
        if (!this.credentials[element].value) {
          allValues = false
        }
        if (this.credentials[element].err) {
          anyErrors = true
        }
      }
      return allValues && !anyErrors
    },
    messages() {
      return {
        editMyDescription: this.$t("message.editMyDescription"),
        description: this.$t("teacherSummary.description"),
        describeYourExperience: this.$t("registration.description.placeholder"),
        void: this.$t("message.void"),
        confirmChanges: this.$t("message.confirmChanges"),
        error: {
          description: this.$t("message.error.description"),
        },
      }
    },
  },
  created() {
    this.credentials.description.value = this.dialogData.data.description
  },

  methods: {
    ...mapActions("profile", {
      updateProfile: "updateProfile",
    }),

    validateInput(value, type) {
      let err = false
      if (value.length < 60) {
        err = true
      } else if (!value) {
        err = true
      }
      this.$set(this.credentials[type], "err", err)
    },

    async submitEditExperience() {
      if (this.isFormValid) {
        const data = {
          description: this.credentials.description.value,
        }
        try {
          this.isLoading()
          await this.updateProfile(data)
          this.simpleSuccess({ text: this.$t("message.success.editDescription") })
        } catch (_) {
          this.simpleError({ text: this.$t("message.error.updateData") })
          return Promise.reject("failed to update password")
        } finally {
          this.isLoaded()
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/sass/rwd_breakpoints.scss";

.title {
  font-weight: 600;
  font-size: 1.25rem;
  color: var(--v-grayscale-darken3);
}

.cancel-button {
  width: 81px !important;
  height: 49px !important;
  font-weight: 600 !important;
  letter-spacing: 0.5px !important;
  text-transform: capitalize;
  border-radius: 8px;
  padding: 0;
  border: 1px solid var(--v-grayscale-lighten4);
  color: var(--v-grayscale-darken3) !important;
}

.input-container {
  position: relative;

  &__title {
    font-weight: 500;
    font-size: 16px;
    color: var(--v-primary-darken3);
  }

  &__text-area {
    width: 100%;
    height: 400px;
    background-color: var(--v-primary-lighten2) !important;
    border: 1px solid var(--v-grayscale-lighten4);
    border-radius: 0.5rem;
    resize: none;
    padding: 0.8rem;

    &:focus {
      outline: none;
    }

    &--error {
      color: var(--v-error-darken1);
      border: 2px solid var(--v-error-darken1);
    }
  }

  &__error-message {
    position: absolute;
    bottom: -25px;
    font-size: 0.875rem;
    color: var(--v-error-darken1);
    @media (max-width: $bp-m) {
      bottom: -40px;
    }
  }
}
</style>
