<template>
  <div>
    <SharedDialog class="dialog" hideConfirmButton width="800">
      <template #title>
        <p class="dialog__title">{{ messages.title }}</p>
      </template>
      <template #content>
        <p class="dialog__description" v-html="messages.description" />
      </template>
    </SharedDialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      note: "",
      lesson_id: null,
    }
  },
  computed: {
    messages() {
      return {
        title: this.$t("informAboutHarmonogramDialog.title"),
        description: this.$t("informAboutHarmonogramDialog.description"),
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.dialog {
  &__title {
    font-weight: bold;
    font-size: 1.5rem;
    letter-spacing: 0.02em;
  }

  &__description {
    font-size: 0.875rem;
    margin: 1.5rem 0 0.5rem 0 !important;
  }
}
</style>
