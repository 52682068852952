<template>
  <vue-timepicker
    v-model="time"
    :return-value.sync="time"
    class="timepicker"
    placeholder="00:00"
    hide-clear-button
    hide-disabled-hours
    fixed-dropdown-button
    :minute-interval="15"
    :hour-range="availableHours"
  >
    <template #dropdownButton>
      <v-icon size="16">$ArrowDown</v-icon>
    </template>
  </vue-timepicker>
</template>

<script>
import VueTimepicker from "vue2-timepicker"
import { getAvailableHours } from "@/utils/functions"

export default {
  name: "BaseTimePicker",
  components: {
    VueTimepicker,
  },
  props: {
    value: {
      type: [String, Object],
      default: null,
    },
    timeFrom: {
      type: Object,
      required: false,
      default: () => {},
    },
  },

  data() {
    return {
      modal: false,
    }
  },

  computed: {
    availableHours() {
      if (!this.timeFrom) {
        return [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23]
      }
      const hoursFrom = +this.timeFrom?.HH

      return getAvailableHours(hoursFrom)
    },
    time: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit("input", val)
      },
    },
  },
  watch: {
    time: {
      handler(val) {
        if (val?.HH && !val?.mm) {
          this.$set(this.time, "mm", "00")
        }
        if (!val?.HH && !val?.mm) {
          this.time = null
        }
      },
      deep: true,
    },
    timeFrom(val) {
      if (+this.time?.HH <= +val?.HH && +val?.HH != 23) {
        this.$set(this.time, "HH", this.timeStringPadding(+val?.HH + 1)) // converts 9 to 09
      }
    },
  },
  methods: {
    timeStringPadding(val) {
      if (val < 10) return `0${val}`
      return String(val)
    },
  },
}
</script>

<style lang="scss" scoped>
.timepicker {
  font-family: "Manrope";
  font-style: normal;
  letter-spacing: 0.01em;
  font-weight: 600;
  line-height: 19px;
  color: var(--v-grayscale-darken3);
  background-color: var(--v-background-base);
  height: 38px !important;
  border: none !important;
  z-index: 0;
  .dropdown-btn {
    padding-right: 16px !important;
  }
  .display-time {
    padding-left: 16px !important;
    border: none !important;
    width: 92px !important;
    height: 38px !important;
    background: var(--v-grayscale-lighten3);
    border-radius: 6px;

    font-family: "Manrope" !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 12px !important;
    line-height: 16px !important;
    letter-spacing: 0.01em !important;

    color: var(--v-grayscale-darken3) !important;
  }
}
</style>
