<template>
  <div class="harmonogram--empty d-flex flex-row align-stretch">
    {{ noVacancy }}
  </div>
</template>

<script>
export default {
  name: "EmptyWeek",
  computed: {
    noVacancy() {
      return this.$t("schedule.noVacancy")
    },
  },
}
</script>

<style lang="scss" scoped>
.harmonogram {
  &--empty {
    display: flex;
    font-weight: bold;
    font-size: 1.5rem;
    margin: 140px auto;
  }
}
</style>
