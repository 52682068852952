export default {
  data: () => ({
    screenWidth: window.innerWidth,
  }),
  watch: {
    screenWidth(newWidth) {
      this.$emit("screen-width-changed", newWidth)
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.updateScreenWidth)
    })
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.updateScreenWidth)
  },
  methods: {
    updateScreenWidth() {
      this.screenWidth = window.innerWidth
    },
  },
}
