<template>
  <SharedDialog
    :title="$t('voiceConfirmation.confirmationDialog.title')"
    :close-btn-title="$t('voiceConfirmation.confirmationDialog.closeBtnTitle')"
    :close-clb="redirectToLesson"
    class="dialog"
    @close-dialog="redirectToLesson"
  >
  </SharedDialog>
</template>

<script>
export default {
  name: "VoiceConfirmationDialog",
  methods: {
    redirectToLesson() {
      this.$router.replace({
        name: "lesson-video-call-vonage",
        query: this.$route.query,
      })
    },
  },
}
</script>
