<template>
  <SharedEditDialog>
    <template #title>
      <p class="title">{{ messages.editPassword }}</p>
    </template>
    <template #content>
      <Loading v-if="loading" class="loader"></Loading>
      <div v-else>
        <div
          v-for="password in passwordElements"
          :key="password.key"
          class="d-flex flex-column mt-6 input-container"
        >
          <label for="name" class="mb-1 input-container__label">{{ password.text }}</label>
          <div class="d-flex flex-row">
            <input
              id="name"
              v-model="credentials[password.name].value"
              :type="credentials[password.name].type"
              :placeholder="password.placeholder"
              name="name"
              class="input-container__input"
              :class="{
                'input-container__input--error': credentials[password.name].errMsg,
              }"
              @change="validateInputDefer($event, password.name)"
              @focus="inputFocused = true"
              @blur="inputFocused = false"
            />
            <v-icon
              v-if="!credentials[password.name].errMsg"
              size="24"
              class="input-container__visibility-button"
              @click="togglePassVisibility(password.name)"
            >
              {{
                credentials[password.name].type === "text"
                  ? "$TogglePasswordHidden"
                  : "$TogglePassword"
              }}
            </v-icon>
            <v-icon
              v-else
              size="24"
              class="input-container__visibility-button"
              @click="togglePassVisibility(password.name)"
            >
              {{
                credentials[password.name].type === "text"
                  ? "$TogglePasswordHiddenError"
                  : "$TogglePasswordError"
              }}
            </v-icon>
          </div>
          <p v-if="credentials[password.name].errMsg" class="input-container__error-meessage">
            {{ credentials[password.name].errMsg }}
          </p>
        </div>
      </div>
    </template>
    <template #actions="{ closeDialog }">
      <BaseButton
        outlined
        color="grayscale-darken3"
        class="mr-4 cancel-button"
        :clear="true"
        @click="closeDialog"
        >{{ messages.void }}</BaseButton
      >

      <BaseButton
        :disabled="!isFormValid || inputFocused"
        class="confirm-button"
        @click="closeDialog(submitEditPassword)"
        >{{ messages.confirmChanges }}</BaseButton
      >
    </template>
  </SharedEditDialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import validateInputDefer from "@/mixins/validateInputDefer"
import Loading from "@/components/UI/Loading.vue"
import spinnerMixin from "@/mixins/spinnerMixin"
import notyMixin from "@/mixins/notyMixin"

export default {
  components: {
    Loading,
  },
  mixins: [validateInputDefer, spinnerMixin, notyMixin],

  data: () => ({
    credentials: {
      actualPassword: {
        value: "",
        errMsg: "",
        timeout: null,
        type: "password",
      },
      newPassword: {
        value: "",
        errMsg: "",
        timeout: null,
        type: "password",
      },
      newPasswordConfirmed: {
        value: "",
        errMsg: "",
        timeout: null,
        type: "password",
      },
    },
    inputFocused: false,
  }),
  computed: {
    passwordElements() {
      return [
        {
          name: "actualPassword",
          text: this.messages.actualPassword,
          placeholder: this.messages.actualPassword,
          checked: this.actualPasswordVisible,
        },
        {
          name: "newPassword",
          text: this.messages.password,
          placeholder: this.messages.newPassword,
          checked: this.newPasswordVisible,
        },
        {
          name: "newPasswordConfirmed",
          text: this.messages.repeatPassword,
          placeholder: this.messages.repeatPassword,
          checked: this.newPasswordConfirmedVisible,
        },
      ]
    },

    messages() {
      return {
        editPassword: this.$t("message.editPassword"),
        actualPassword: this.$t("message.actualPassword"),
        password: this.$t("message.profile.password"),
        newPassword: this.$t("message.newPassword"),
        repeatPassword: this.$t("message.repeatPassword"),
        void: this.$t("message.void"),
        confirmChanges: this.$t("message.confirmChanges"),

        error: {
          actualPassword: this.$t("message.error.required"),
          newPassword: this.$t("message.passwordAtLeast8Characters"),
          newPasswordConfirmed: this.$t("message.passwordsMustBeTheSame"),
        },
      }
    },

    ...mapGetters("layout", {
      dialogData: "getDialogConfig",
    }),

    actualPasswordVisible() {
      return this.credentials.actualPassword.type === "text"
    },

    newPasswordVisible() {
      return this.credentials.newPassword.type === "text"
    },

    newPasswordConfirmedVisible() {
      return this.credentials.newPasswordConfirmed.type === "text"
    },

    isFormValid() {
      let allValues = true
      let anyErrors = false

      for (const element of Object.keys(this.credentials)) {
        if (!this.credentials[element].value) {
          allValues = false
        }
        if (this.credentials[element].errMsg) {
          anyErrors = true
        }
      }
      return allValues && !anyErrors
    },
  },

  methods: {
    ...mapActions("profile", {
      updateProfile: "updateProfile",
    }),

    togglePassVisibility(password) {
      if (this.credentials[password].type === "password") this.credentials[password].type = "text"
      else this.credentials[password].type = "password"
    },

    validateInput(value, type) {
      if (type === "actualPassword") {
        const validLength = value.length >= 8 ? "" : this.messages.error?.newPassword
        const errorMsg = value.length > 0 ? validLength : this.messages.error.required

        this.$set(this.credentials[type], "errMsg", errorMsg)
        return
      }
      if (type === "newPassword") {
        const newPasswordConfirmedExist = !!this.credentials.newPasswordConfirmed.value
        const inputValid = newPasswordConfirmedExist
          ? value === this.credentials.newPasswordConfirmed.value && value.length >= 8
          : value.length >= 8
        const errorType =
          newPasswordConfirmedExist && value.length >= 8 ? "newPasswordConfirmed" : type
        const errorMsg = inputValid ? "" : this.messages.error?.[errorType]

        this.$set(this.credentials[type], "errMsg", errorMsg)

        if (newPasswordConfirmedExist) {
          const errorMsg =
            value === this.credentials.newPasswordConfirmed.value && value.length >= 8
              ? ""
              : this.messages.error?.newPasswordConfirmed

          this.$set(this.credentials.newPasswordConfirmed, "errMsg", errorMsg)
        }
        return
      }
      if (type === "newPasswordConfirmed") {
        const newPasswordExist = !!this.credentials.newPassword.value
        const errorMsg =
          value === this.credentials.newPassword.value ? "" : this.messages.error?.[type]
        this.$set(this.credentials[type], "errMsg", errorMsg)

        if (newPasswordExist) {
          const errorMsg =
            value === this.credentials.newPassword.value ? "" : this.messages.error?.[type]

          this.$set(this.credentials.newPassword, "errMsg", errorMsg)
        }
        return
      }
    },

    async submitEditPassword() {
      if (this.isFormValid) {
        const data = {
          currentPassword: this.credentials.actualPassword.value,
          password: this.credentials.newPassword.value,
          passwordConfirmation: this.credentials.newPasswordConfirmed.value,
        }
        try {
          this.isLoading()
          await this.updateProfile(data)
          this.simpleSuccess({ text: this.$t("message.success.password") })
        } catch (_) {
          this.simpleError({ text: this.$t("message.error.actualPassword") })
          return Promise.reject("failed to update password")
        } finally {
          this.isLoaded()
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.title {
  font-weight: 600;
  font-size: 1.25rem;
  color: var(--v-grayscale-darken3);
}

.cancel-button {
  width: 81px !important;
  height: 49px !important;
  font-weight: 600 !important;
  letter-spacing: 0.5px !important;
  text-transform: capitalize;
  border-radius: 8px;
  padding: 0;
  border: 1px solid var(--v-grayscale-lighten4);
  color: var(--v-grayscale-darken3) !important;
}

.input-container {
  position: relative;

  &__visibility-button {
    width: 28px;
    position: absolute;
    cursor: pointer;
    transform: translateY(50%);
    right: 35px;

    &:hover {
      opacity: 1;
    }
  }

  &__label {
    font-weight: 500;
    font-size: 1rem;
    color: var(--v-primary-darken3);
  }

  &__input {
    min-width: 100%;
    width: 100%;
    height: 48px;
    padding: 0.75em 1rem;
    background: var(--v-primary-lighten2);
    border: 1px solid var(--v-grayscale-lighten4);
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.25);
    border-radius: 8px;

    &--error {
      color: var(--v-error-darken1);
      border: 2px solid var(--v-error-darken1);
    }

    &:focus {
      outline: 3px solid var(--v-primary-base) !important;
    }
  }
  &__error-meessage {
    position: absolute;
    bottom: -25px;
    font-size: 0.875rem;
    width: 100%;
    text-align: right;
    color: var(--v-error-darken1);
  }
}
.confirm-button {
  min-width: 147px;
}

.loader {
  margin: 120px 0;
}
</style>
