const path = {
  startArchive: `lessons/archives/create`,
  selectStream: `/lessons/archives/add_stream`,
  stop: `/lessons/archives/stop`,
}

export default {
  /**
   * // https://tokbox.com/developer/rest/#start_archive
   * @param {*} axios
   * @param {*} sessionId
   * @returns
   */
  startArchiveRecording: (axios) => async (lessonId) => {
    const res = await axios.post(path.startArchive, {
      lesson_id: lessonId,
    })
    return res
  },
  selectArchiveStream: (axios) => async (lessonId, streamId) => {
    const res = await axios.post(path.selectStream, {
      stream_id: streamId,
      lesson_id: lessonId,
    })
    return res
  },
  stopArchiveRecording: (axios) => async (lessonId) => {
    const res = await axios.post(path.stop, { lesson_id: lessonId })
    return res
  },
}
