import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import vuetify from "./plugins/vuetify"
import "./plugins/optionalChaining"
import "./plugins/driver"
import i18n from "@/languages"
import VueNoty from "vuejs-noty"
import "./vee-validate.js"
import VueMask from "v-mask"
import "roboto-fontface/css/roboto/roboto-fontface.css"
import "material-design-icons-iconfont/dist/material-design-icons.css"
import "vue2-timepicker/dist/VueTimepicker.css"
import VueTelInputVuetify from "vue-tel-input-vuetify/lib"
import * as Sentry from "@sentry/vue"
import { Integrations } from "@sentry/tracing"

import BaseButton from "@/components/UI/Base/BaseButton"
import BasePhoneInput from "@/components/UI/Base/BasePhoneInput"
import BaseInput from "@/components/UI/Base/BaseInput"
import BaseSelect from "@/components/UI/Base/BaseSelect"
import BaseCheckbox from "@/components/UI/Base/BaseCheckbox"
import BaseCheckboxReg from "@/components/UI/Base/BaseCheckboxReg"
import BaseDatePicker from "@/components/UI/Base/BaseDatePicker"
import BaseTimePicker from "@/components/UI/Base/BaseTimePicker"
import BaseSwitcher from "@/components/UI/Base/BaseSwitcher"
import Loading from "@/components/UI/Loading"
import SharedCard from "@/components/UI/Shared/SharedCard"
import SharedEditDialog from "@/components/UI/Shared/SharedEditDialog"
import SharedDialog from "@/components/UI/Shared/SharedDialog"
import SharedInfoCard from "@/components/UI/Shared/SharedInfoCard"

Vue.config.productionTip = false

Vue.component("BaseButton", BaseButton)
Vue.component("BaseCheckbox", BaseCheckbox)
Vue.component("BaseCheckboxReg", BaseCheckboxReg)
Vue.component("BaseInput", BaseInput)
Vue.component("BaseSelect", BaseSelect)
Vue.component("BaseDatePicker", BaseDatePicker)
Vue.component("BaseTimePicker", BaseTimePicker)
Vue.component("BasePhoneInput", BasePhoneInput)
Vue.component("Loading", Loading)
Vue.component("SharedCard", SharedCard)
Vue.component("SharedDialog", SharedDialog)
Vue.component("SharedInfoCard", SharedInfoCard)
Vue.component("SharedEditDialog", SharedEditDialog)
Vue.component("BaseSwitcher", BaseSwitcher)

Vue.use(VueNoty, {
  theme: "nest",
  timeout: 4000,
  progressBar: true,
  layout: "bottomCenter",
  visibilityControl: true,
  force: true,
  killer: true,
  animation: {
    open: "animated fadeIn",
    close: "animated fadeOut",
  },
})

Vue.use(VueTelInputVuetify, {
  vuetify,
})

Vue.use(VueMask)

Sentry.init({
  Vue,
  dsn: "https://2987cc75bd5440299643f6b684f63b73@sentry.apploversoftware.com/22",
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ["multilango-frontend.herokuapp.com", "app.multilango.com/", /^\//],
    }),
  ],
  tracesSampleRate: 1.0,
  environment: process.env.NODE_ENV,
})

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app")
