const mixin = {
  methods: {
    simpleSuccess({ text = this.$t("notifications.dataUpdateSuccess") } = {}, timeout = 1500) {
      this.$noty.success(text, {
        timeout,
      })
    },
    simpleError({ text = this.$t("notifications.dataUpdateError") } = {}, timeout = 1500) {
      this.$noty.error(text, {
        timeout,
      })
      return false
    },
    simpleInfo({ text = this.$t("notifications.attention") } = {}, timeout = 1500) {
      this.$noty.info(text, {
        timeout,
      })

      return false
    },
  },
}
export default mixin
