<template>
  <SharedEditDialog
    ><template #title>
      <p class="title mb-6">{{ $t("message.socialMedia") }}</p>
    </template>
    <template #content>
      <Loading v-if="loading" class="my-10"></Loading>
      <template v-else>
        <div
          v-for="(link, idx) in allLinks"
          :key="link + idx"
          class="d-flex flex-column input-container"
        >
          <BaseInput
            :id="`link-${idx}`"
            v-model="allLinks[idx]"
            type="text"
            :placeholder="messages.linkToWebsite"
            name="link"
            solo
            dense
            @input="onInput($event, idx)"
          />
        </div>
        <div class="d-flex flex-row align-center add-link" @click="addNewLink">
          <v-icon :size="20">$Add</v-icon>
          <p class="ml-4 add-link__text">{{ messages.addNewLink }}</p>
        </div></template
      >
    </template>
    <template #actions="{ closeDialog }">
      <BaseButton
        outlined
        :clear="true"
        color="grayscale-darken3"
        class="mr-4 cancel-button"
        @click="closeDialog"
        >{{ messages.void }}</BaseButton
      >

      <BaseButton
        :disabled="!isNotEmpty"
        class="confirm-button"
        @click="closeDialog(submitEditSocialMedia)"
        >{{ messages.confirmChanges }}</BaseButton
      >
    </template>
  </SharedEditDialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import notyMixin from "@/mixins/notyMixin"
import spinnerMixin from "@/mixins/spinnerMixin"

export default {
  mixins: [notyMixin, spinnerMixin],
  data: () => ({
    cooldown: null,
    addedLinks: [],
  }),
  computed: {
    ...mapGetters("profile", { links: "getSocialLinks" }),
    messages() {
      return {
        socialMedia: this.$t("message.socialMedia"),
        linkToWebsite: this.$t("message.linkToWebsite"),
        addNewLink: this.$t("message.addNewLink"),
        void: this.$t("message.void"),
        confirmChanges: this.$t("message.confirmChanges"),
      }
    },
    isNotEmpty() {
      return this.links.length > 0
    },
    allLinks() {
      return [...this.links, ...this.addedLinks]
    },
  },
  methods: {
    ...mapActions("profile", ["updateProfile", "updateSkillData"]),
    addNewLink() {
      this.updateSkillData({
        key: "social_links",
        value: [...this.allLinks, ""],
      })
      return
    },
    async submitEditSocialMedia() {
      if (this.isNotEmpty) {
        try {
          this.isLoading()
          await this.updateProfile({ social_links: this.allLinks })
        } catch (err) {
          console.err(err)
          this.simpleError({ text: this.$t("message.error.updateData") })
        } finally {
          this.isLoaded()
        }
      }
    },

    onInput(link, idx) {
      if (!link) return
      if (this.allLinks?.includes(link)) return
      clearTimeout(this.cooldown)

      this.cooldown = setTimeout(() => {
        const list = [...this.links]
        list[idx] = link
        this.updateSkillData({
          key: "social_links",
          value: list,
        })
      }, 500)
    },
  },
}
</script>

<style lang="scss" scoped>
.title {
  font-weight: 600;
  font-size: 1.25rem;
  color: var(--v-grayscale-darken3);
}

.cancel-button {
  width: 81px !important;
  height: 49px !important;
  font-weight: 600 !important;
  letter-spacing: 0.5px !important;
  text-transform: capitalize;
  border-radius: 8px;
  padding: 0;
  border: 1px solid var(--v-grayscale-lighten4);
  color: var(--v-grayscale-darken3) !important;
}

.add-link {
  border: 1px solid var(--v-grayscale-lighten4);
  border-radius: 6px;
  padding: 0.8rem 1rem;
  cursor: pointer;

  &__text {
    font-weight: 600;
    font-size: 0.875rem;
    color: var(--v-grayscale-darken3);
  }
}

.input-container {
  &__title {
    font-weight: 500;
    font-size: 16px;
    color: var(--v-primary-darken3);
  }

  &__label {
    font-weight: 500;
    font-size: 1rem;
    color: var(--v-primary-darken3);
  }

  &__input {
    width: 100%;
    height: 48px;
    width: 100%;
    padding: 0.75em 1rem;
    background: var(--v-primary-lighten2);
    border: 1px solid var(--v-grayscale-lighten4);
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.25);
    border-radius: 8px;

    &--error {
      color: var(--v-error-darken1);
      border: 2px solid var(--v-error-darken1);
    }

    &:focus {
      outline: 3px solid var(--v-primary-base) !important;
    }
  }
}
</style>
