import Vue from "vue"
import Vuex from "vuex"
import pathify from "vuex-pathify"

import layout from "./layout"
import profile from "./profile"
import teacher from "./teacher"
import student from "./student"
import user from "./user"
import lessons from "./lessons"

Vue.use(Vuex)

const store = new Vuex.Store({
  plugins: [pathify.plugin],
  state: {
    locale: "pl",
    debug: process.env.NODE_ENV === "development",
    error: {
      server: "",
    },
  },
  getters: {
    getLocale: (state) => state.locale,
    getErrorFromServer: (state) => state.error.server,
  },
  mutations: {
    SET_LOCALE(state, locale) {
      state.locale = locale
    },
    SET_ERROR_FROM_SERVER(state, payload) {
      Vue.set(state.error, "server", payload)
    },
  },
  actions: {},
  modules: {
    layout,
    profile,
    teacher,
    student,
    user,
    lessons,
  },
})

export default store
