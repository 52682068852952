var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('SharedEditDialog',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('p',{staticClass:"title"},[_vm._v(_vm._s(_vm.messages.editPassword))])]},proxy:true},{key:"content",fn:function(){return [(_vm.loading)?_c('Loading',{staticClass:"loader"}):_c('div',_vm._l((_vm.passwordElements),function(password){return _c('div',{key:password.key,staticClass:"d-flex flex-column mt-6 input-container"},[_c('label',{staticClass:"mb-1 input-container__label",attrs:{"for":"name"}},[_vm._v(_vm._s(password.text))]),_c('div',{staticClass:"d-flex flex-row"},[((_vm.credentials[password.name].type)==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.credentials[password.name].value),expression:"credentials[password.name].value"}],staticClass:"input-container__input",class:{
              'input-container__input--error': _vm.credentials[password.name].errMsg,
            },attrs:{"id":"name","placeholder":password.placeholder,"name":"name","type":"checkbox"},domProps:{"checked":Array.isArray(_vm.credentials[password.name].value)?_vm._i(_vm.credentials[password.name].value,null)>-1:(_vm.credentials[password.name].value)},on:{"change":[function($event){var $$a=_vm.credentials[password.name].value,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.credentials[password.name], "value", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.credentials[password.name], "value", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.credentials[password.name], "value", $$c)}},function($event){return _vm.validateInputDefer($event, password.name)}],"focus":function($event){_vm.inputFocused = true},"blur":function($event){_vm.inputFocused = false}}}):((_vm.credentials[password.name].type)==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.credentials[password.name].value),expression:"credentials[password.name].value"}],staticClass:"input-container__input",class:{
              'input-container__input--error': _vm.credentials[password.name].errMsg,
            },attrs:{"id":"name","placeholder":password.placeholder,"name":"name","type":"radio"},domProps:{"checked":_vm._q(_vm.credentials[password.name].value,null)},on:{"change":[function($event){return _vm.$set(_vm.credentials[password.name], "value", null)},function($event){return _vm.validateInputDefer($event, password.name)}],"focus":function($event){_vm.inputFocused = true},"blur":function($event){_vm.inputFocused = false}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.credentials[password.name].value),expression:"credentials[password.name].value"}],staticClass:"input-container__input",class:{
              'input-container__input--error': _vm.credentials[password.name].errMsg,
            },attrs:{"id":"name","placeholder":password.placeholder,"name":"name","type":_vm.credentials[password.name].type},domProps:{"value":(_vm.credentials[password.name].value)},on:{"change":function($event){return _vm.validateInputDefer($event, password.name)},"focus":function($event){_vm.inputFocused = true},"blur":function($event){_vm.inputFocused = false},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.credentials[password.name], "value", $event.target.value)}}}),(!_vm.credentials[password.name].errMsg)?_c('v-icon',{staticClass:"input-container__visibility-button",attrs:{"size":"24"},on:{"click":function($event){return _vm.togglePassVisibility(password.name)}}},[_vm._v(" "+_vm._s(_vm.credentials[password.name].type === "text" ? "$TogglePasswordHidden" : "$TogglePassword")+" ")]):_c('v-icon',{staticClass:"input-container__visibility-button",attrs:{"size":"24"},on:{"click":function($event){return _vm.togglePassVisibility(password.name)}}},[_vm._v(" "+_vm._s(_vm.credentials[password.name].type === "text" ? "$TogglePasswordHiddenError" : "$TogglePasswordError")+" ")])],1),(_vm.credentials[password.name].errMsg)?_c('p',{staticClass:"input-container__error-meessage"},[_vm._v(" "+_vm._s(_vm.credentials[password.name].errMsg)+" ")]):_vm._e()])}),0)]},proxy:true},{key:"actions",fn:function(ref){
            var closeDialog = ref.closeDialog;
return [_c('BaseButton',{staticClass:"mr-4 cancel-button",attrs:{"outlined":"","color":"grayscale-darken3","clear":true},on:{"click":closeDialog}},[_vm._v(_vm._s(_vm.messages.void))]),_c('BaseButton',{staticClass:"confirm-button",attrs:{"disabled":!_vm.isFormValid || _vm.inputFocused},on:{"click":function($event){return closeDialog(_vm.submitEditPassword)}}},[_vm._v(_vm._s(_vm.messages.confirmChanges))])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }