import { delay } from "@/utils/functions"

export default {
  data() {
    return {
      loading: false,
      $_spinnerMixin_delay: 300,
    }
  },
  methods: {
    isLoading() {
      this.loading = true
    },
    async isLoaded() {
      await delay(this.$data.$_spinnerMixin_delay)
      this.loading = false
    },
  },
}
