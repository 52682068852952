export default {
  methods: {
    addMinutes(date, minutes) {
      if (date) return new Date(date.getTime() + minutes * 60000)
    },
    subtractMinutes(date, minutes) {
      if (date) return new Date(date.getTime() - minutes * 60000)
    },
    addHours(date, hours) {
      const newDate = new Date(date)
      newDate.setHours(newDate.getHours() + hours)
      return newDate
    },
  },
}
