<template>
  <div>
    <SharedDialog class="dialog">
      <template #title>
        <div class="d-flex flex-row justify-space-between align-start">
          <h2 class="dialog__title">{{ messages.rateLessonDialogTitle }}</h2>
        </div>
      </template>
      <template #content>
        <Loading v-if="loading" class="loader"></Loading>
        <template v-else>
          <div class="dialog__star-box ml-4 mt-8 d-flex flex-row">
            <div
              v-for="(star, index) in starState"
              :key="star.name"
              class="dialog__star-box__item pr-3"
              @mouseover="fillStars(star.name)"
              @mouseleave="clearStars(index)"
              @click="confirmRate(star.name)"
            >
              <v-icon v-if="star.empty"> $StarEmpty </v-icon>
              <v-icon v-if="star.filled"> $StarFilled </v-icon>
            </div>
          </div>
          <p class="dialog__description">
            {{ messages.rateLessonDialogDescription }}
          </p>
          <textarea
            v-model="comment"
            maxlength="255"
            solo
            auto-grow
            class="dialog__text-area mb-2"
          ></textarea>
        </template>
      </template>
      <template #actions="{ closeDialog }">
        <BaseButton
          :disabled="isDisabled || loading"
          class="confirm-button"
          @click="closeDialog(setRating)"
          >{{ rateCaption }}</BaseButton
        >
      </template>
    </SharedDialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import notyMixin from "@/mixins/notyMixin"
import spinnerMixin from "@/mixins/spinnerMixin"

export default {
  mixins: [notyMixin, spinnerMixin],
  data() {
    return {
      starState: [
        {
          name: "one",
          empty: true,
          filled: false,
        },
        {
          name: "two",
          empty: true,
          filled: false,
        },
        {
          name: "three",
          empty: true,
          filled: false,
        },
        {
          name: "four",
          empty: true,
          filled: false,
        },
        {
          name: "five",
          empty: true,
          filled: false,
        },
      ],
      rateChecked: false,
      rating: null,
      comment: "",
    }
  },
  computed: {
    messages() {
      return {
        rateLessonDialogTitle: this.$t("message.rateLesson"),
        rateLessonDialogDescription: this.$t("message.rateLessonDialogDescription"),
        editRating: this.$t("message.editRating"),
        addRating: this.$t("message.addRating"),
      }
    },
    ...mapGetters("layout", {
      rateLessonDialogData: "getDialogConfig",
    }),
    rateCaption() {
      const rate = this.rateLessonDialogData?.data.item.rating
      return rate?.comment || rate?.rate ? this.messages.editRating : this.messages.addRating
    },
    isDisabled() {
      return !this.rating || !this.comment
    },
  },
  created() {
    this.comment = this.rateLessonDialogData.data.item?.rating?.comment || ""
    this.rating = this.rateLessonDialogData.data.item?.rating?.value || null

    if (this.rating) {
      this.confirmRate(this.rating)
      this.clearStars()
    }
  },
  methods: {
    ...mapActions("lessons", {
      rateLesson: "rateLesson",
    }),
    fillStars(lastStar) {
      for (const value of Object.values(this.starState)) {
        value.empty = false
        value.filled = true
        if (value.name === lastStar) return
      }
    },
    clearStars(index) {
      if (!this.rateChecked) {
        for (const value of Object.values(this.starState)) {
          value.empty = true
          value.filled = false
        }
      } else {
        let i = 0
        for (const value of Object.values(this.starState)) {
          i += 1
          if (index + 1 <= this.rating) {
            continue
          }
          if (i - 1 >= this.rating) {
            value.empty = true
            value.filled = false
          }
        }
      }
    },
    confirmRate(checkedStar) {
      this.rateChecked = true
      for (const value of Object.values(this.starState)) {
        value.empty = true
        value.filled = false
      }
      for (const value of Object.values(this.starState)) {
        value.empty = false
        value.filled = true
        if (value.name === checkedStar) {
          this.rating = this.getRating(value.name)
          break
        }
      }
    },
    getRating(rateString) {
      switch (rateString) {
        case "one":
          return 1
        case "two":
          return 2
        case "three":
          return 3
        case "four":
          return 4
        case "five":
          return 5
      }
    },
    async setRating() {
      const data = {
        lesson_id: this.rateLessonDialogData.data.item.id,
        value: this.rating,
        comment: this.comment,
        mode: this.rateLessonDialogData.data.item.rating ? "update" : "create",
      }
      try {
        this.isLoading()
        await this.rateLesson(data)
        this.simpleSuccess({ text: this.$t("message.success.rateLesson") })
      } catch (err) {
        this.simpleError({ text: this.$t("message.error.updateData") })
        console.error(err)
      } finally {
        this.isLoaded()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.dialog {
  &__text-area {
    width: 100%;
    height: 94px;
    background-color: #fafbff !important;
    border: 1px solid #eceff9;
    border-radius: 0.5rem;
    resize: none;
    padding: 0.4rem 0.6rem;

    &:focus {
      outline: none;
    }
  }
  &__description {
    font-size: 0.875rem;
    margin: 1.5rem 0 1rem 0 !important;
  }
  &__star-box {
    &__item {
      height: 55px;
      width: 55px;
      cursor: pointer;
    }
  }
}

.loader {
  margin: 105px 0;
}

::v-deep svg {
  position: fixed;
  height: 45px;
}
</style>
