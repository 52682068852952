<template>
  <div>
    <SharedDialog class="dialog">
      <template #title>
        <p class="dialog__title">{{ $t("profile.preview.title") }}</p>
      </template>
      <template #content>
        <p class="dialog__description">
          {{ $t(`profile.preview.${video ? "videoDescription" : "imageDescription"}`) }}
        </p>
        <div class="w-100 d-flex align-center justify-center">
          <img v-if="image" :src="`${image.currentSrc}`" alt="" class="preview ma-4 rounded-lg" />
          <video v-if="video" width="100%" class="preview" controls preload="metadata">
            <source :src="`${video.currentSrc}#t=2`" type="video/mp4" />
          </video>
        </div>
      </template>
      <template #actions="{ closeDialog }">
        <BaseButton @click="closeDialog()">{{ $t("message.close") }}</BaseButton></template
      >
    </SharedDialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  name: "PreviewDialog",
  data() {
    return {
      video: null,
      image: null,
    }
  },
  computed: {
    ...mapGetters("layout", {
      previewDialogData: "getDialogConfig",
    }),
    messages() {
      return {
        preview: this.$t("message.preview"),
        previewDescription: this.$t("message.previewDescription"),
      }
    },
    ...mapGetters("layout", {
      previewDialogData: "getDialogConfig",
    }),
  },
  created() {
    this.video = this.previewDialogData.data?.video
    this.image = this.previewDialogData.data?.image
  },
}
</script>

<style lang="scss" scoped>
.dialog {
  &__title {
    font-weight: bold;
    font-size: 1.5rem;
    letter-spacing: 0.02em;
  }

  &__text-area {
    width: 100%;
    height: 94px;
    background-color: #fafbff !important;
    border: 1px solid #eceff9;
    border-radius: 0.5rem;
    resize: none;
    padding: 0.4rem 0.6rem;

    &:focus {
      outline: none;
    }
  }
  &__description {
    font-size: 0.875rem;
    margin: 0.5rem 0 1rem 0 !important;
  }
}
.preview {
  max-height: 400px;
}
</style>
