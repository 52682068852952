<template>
  <Navigation />
</template>

<script>
import Navigation from "@/components/Navigation/Navigation"

export default {
  name: "Header",

  components: {
    Navigation,
  },
}
</script>
