import Driver from "driver-js-dynamic-wrapper"
import "driver.js/dist/driver.min.css"
import Vue from "vue"

const MyPlugin = {
  install(vue) {
    vue.prototype.$driver = new Driver()
  },
}
Vue.use(MyPlugin)
