import camelize from "camelize2"
import store from "@/store"
import router from "@/router"
import Vue from "vue"
import i18n from "@/languages/index"
import _throttle from "lodash.throttle"

const setErrFn = (err) => {
  store.commit("SET_ERROR_FROM_SERVER", err)
}
const logOut = () => {
  router.push({ name: "login" }).catch(() => {})
  store.dispatch("user/logOut")
  _throttle(Vue.noty.error(i18n.t("message.error.youHaveBeenLoggedOut")), 200)
}

const errorHandler = (errorResponse) => {
  let returnStatement

  try {
    const { data, status, statusText } = errorResponse.response

    switch (status) {
      case 401:
        returnStatement = { error: errorResponse, status }
        logOut()
        break

      case 500:
        returnStatement = { error: errorResponse, status }
        break

      case 403:
        returnStatement = { errors: camelize(data?.error), status }
        break

      default:
        returnStatement = { error: errorResponse, status }
        break
    }

    setErrFn(statusText)
  } catch (error) {
    returnStatement = { error }
    setErrFn("Network error!")
  }

  return returnStatement
}

export default errorHandler
