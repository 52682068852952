<template>
  <v-row justify="center" align="center" class="flex-column">
    <slot name="logo">
      <router-link :to="routeToLogin">
        <img src="@/assets/logo.svg" alt="" class="ma-6 logo" />
      </router-link>
    </slot>

    <v-card class="base-card">
      <slot></slot>
    </v-card>
  </v-row>
</template>

<script>
export default {
  name: "SharedCard",
  computed: {
    routeToLogin() {
      if (this.$route.name === "login") return ""
      else return this.$route.name != "registration" ? "/login" : "/login/teacher"
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/sass/rwd_breakpoints.scss";
.base-card {
  width: 776px;
  max-width: 90%;
  padding: 4rem 1.5rem 3rem;
  background: var(--v-grayscale-lighten3);
  border-radius: 1rem !important;
  box-shadow: none !important;
}
.logo {
  cursor: pointer;
}

@media (min-width: ($bp-mt + 1)) {
  .base-card {
    padding: 4rem 8rem 3rem;
  }
}
</style>
