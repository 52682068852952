<template>
  <v-tooltip
    v-model="show"
    bottom
    :nudge-top="-10"
    content-class="tooltip-content"
    :open-on-click="true"
    color="white"
    :open-on-hover="false"
    :disabled="!dataHasChange"
  >
    <div class="tooltip-content__triangle"></div>

    <template #activator="{ on, attrs }">
      <div v-show="!!range" class="week" v-bind="attrs" v-on="on" @click="openToolbar">
        <v-icon :disabled="isFirstWeek" :class="className('prev')" @click="$emit('prev')">
          $ArrowLeft
        </v-icon>
        <div class="week__range">{{ range }}</div>
        <v-icon :disabled="isLastWeek" :class="className('next')" @click="$emit('next')">
          $ArrowRight
        </v-icon>
      </div>
    </template>
    <span>{{ $t("message.confirmChangesInThisWeek") }}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: "WeekSwitch",
  props: {
    range: {
      type: String,
      required: true,
      default: "",
    },
    isFirstWeek: {
      type: Boolean,
      required: true,
      default: true,
    },
    isLastWeek: {
      type: Boolean,
      required: true,
      default: false,
    },
    dataHasChange: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    show: false,
    closeTimeout: null,
  }),
  methods: {
    className(type = "prev") {
      const disabled = (type === "prev" && this.isFirstWeek) || (type === "next" && this.isLastWeek)
      return `week__${disabled ? type + "--disabled" : type}`
    },
    openToolbar() {
      this.show = !this.show
      clearTimeout(this.closeTimeout)
      if (this.show) {
        this.closeTimeout = setTimeout(() => {
          if (this.show) this.show = false
        }, 6000)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.week {
  min-width: 250px;
  max-width: 300px;
  justify-content: space-between;
  padding: 0 1rem;
  display: flex;
  &__prev,
  &__next {
    &:not(--disabled) {
      cursor: pointer;
    }
    &--disabled {
      opacity: 0.25;
    }
    cursor: pointer;
  }
  &__range {
    font-size: 1rem;
  }
}
.tooltip-content {
  opacity: 1 !important;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 8px;
  color: #304154;
  padding: 13px 22px;
  z-index: 15;
  max-width: 250px;

  &__triangle {
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    top: -13px;
    left: 53px;
    border-width: 0 11px 13px 11px;
    border-color: transparent transparent #ffffff transparent;
    filter: drop-shadow(1px 0px 0px rgba(0, 1, 1, 4.5));
  }
}
</style>
