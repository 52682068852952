<template>
  <v-checkbox
    hide-details
    :label="initialValue.text"
    :value="initialValue.checked"
    @change="$emit('change', $event)"
  ></v-checkbox>
</template>

<script>
export default {
  name: "BaseCheckbox",
  props: {
    initialValue: {
      type: Object,
      default: () => ({
        text: "",
        checked: false,
      }),
    },
  },
}
</script>

<style></style>
