<template>
  <div>
    <div class="schedule__row d-flex mt-8" :style="weekDaysStyle">
      <div
        v-for="(day, i) in week"
        :key="i"
        class="schedule__date d-flex flex-column align-stretch mb-4"
      >
        <span v-for="part in getDayDateFormatted(day)" :key="part" class="day-header">{{
          part
        }}</span>
      </div>
    </div>

    <div class="schedule__row d-flex">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "ScheduleDay",
  props: {
    week: {
      type: Array,
      required: true,
      default: () => [],
    },
    weekDaysStyle: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    pattern: {
      en: " ",
      pl: "., ",
    },
  }),
  computed: {
    locale() {
      return this.$i18n?.locale ?? "en"
    },
    dayDateFormatted() {
      return this.getDateFormat(this.dayDate).split(this.pattern[this.locale]).sort().reverse()
    },
  },
  methods: {
    getDateFormat(date) {
      return new Date(date).toLocaleDateString(this.locale, {
        weekday: "short",
        day: "numeric",
      })
    },
    getDayDateFormatted(dayDate) {
      return this.getDateFormat(dayDate).split(this.pattern[this.locale]).sort().reverse()
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/sass/rwd_breakpoints.scss";

.day-header {
  font-weight: 600;
  &:first-child {
    margin-bottom: 8px;
  }
}
.schedule__row {
  @media (min-width: ($bp-mt + 1)) {
    width: 560px !important;
  }
}
</style>
