<template>
  <div class="ml-6 flex-row justify-space-around type-switcher">
    <p
      id="left"
      class="type-switcher__item-day"
      :class="{ 'type-switcher__item-week--active': leftSwitchActive }"
      @click="$emit('switch', $event)"
    >
      {{ rightSwitchText }}
    </p>
    <p
      id="right"
      class="type-switcher__item-week"
      :class="{ 'type-switcher__item-week--active': rightSwitchActive }"
      @click="$emit('switch', $event)"
    >
      {{ leftSwitchText }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    leftSwitchText: {
      type: String,
      required: true,
    },
    rightSwitchText: {
      type: String,
      required: true,
    },
    leftSwitchActive: {
      type: Boolean,
      required: true,
    },
    rightSwitchActive: {
      type: Boolean,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.type-switcher {
  display: flex;
  width: 175px;
  height: 32px;
  border: 1px solid var(--v-grayscale-lighten4);
  border-radius: 6px;

  &__item-day {
    width: 50%;
    line-height: 30px;
    text-align: center;
    cursor: pointer;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;

    &--active {
      color: var(--v-grayscale-darken3);
      background: var(--v-grayscale-darken4);
    }
  }

  &__item-week {
    width: 50%;
    line-height: 30px;
    text-align: center;
    cursor: pointer;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;

    &--active {
      color: var(--v-grayscale-darken3);
      background: var(--v-grayscale-darken4);
    }
  }
}
</style>
