<template>
  <v-btn
    v-bind="$attrs"
    :ripple="false"
    :color="color"
    class="base-button"
    :class="classes"
    v-on="$listeners"
  >
    <!-- @slot The default Vue slot. -->
    <slot></slot>
  </v-btn>
</template>

<script>
/**
 * Component has all props extended from [vuetify](https://vuetifyjs.com/en/api/v-btn/#props)
 */
export default {
  name: "BaseButton",
  props: {
    /**
     * removes predefined button size(170px)
     */
    sizeAuto: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: "primary",
    },
    clear: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      return {
        "v-size--auto": this.sizeAuto,
        "base-button--primary": this.$attrs?.color == "primary",
        "base-button--clear": this.clear,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.v-btn.v-btn--disabled.base-button--primary {
  &.v-btn--disabled {
    background-color: var(--v-primary-lighten1) !important;
    color: white !important;
  }
}

.base-button:not(.base-button--clear) {
  letter-spacing: normal;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;

  min-width: 170px !important;
  padding: 0 0.75rem !important;
  height: 48px !important;
  border-radius: 0.5rem;
  text-transform: none;

  &.v-size--large {
    min-width: 205px !important;
  }
  &.v-size--auto {
    min-width: auto !important;
  }
  &.primary {
    &:hover {
      background-color: var(--v-primary-darken1) !important;
      opacity: 1;
    }

    &:focus {
      background-color: var(--v-primary-darken2) !important;
      opacity: 1;
    }
  }

  &.theme--light.v-btn.v-btn--has-bg {
    &.primary {
      &:not(.v-btn--disabled):not(.delete-button):not(.confirm-button) {
        background: var(--v-primary-base) !important;
      }
    }
    &.secondary {
      &:not(.v-btn--disabled) {
        background: var(--v-secondary-base) !important;
      }
    }
  }
}

.base-button--outlined:not(.base-button--clear) {
  background-color: transparent !important;

  &:hover {
    background-color: transparent !important;
  }

  &:disabled {
    background-color: var(--v-primary-lighten1) !important;
  }
}

.base-button--outlinedTransparentGray:not(.base-button--clear) {
  background-color: transparent !important;
  border: 1px solid var(--v-grayscale-lighten4);
  color: var(--v-grayscale-darken3);

  &:hover {
    background-color: transparent !important;
  }

  &:disabled {
    background-color: var(--v-grayscale-darken2) !important;
  }
}
</style>
